import Card1Icon from 'src/assets/images/about-assets/values/CelebratePeople&Community.svg';
import Card2Icon from 'src/assets/images/about-assets/values/SpeakwithEmpathy&Honesty.svg';
import Card3Icon from 'src/assets/images/about-assets/values/EmbracePerseverance&Flexibility.svg';
import Card4Icon from 'src/assets/images/about-assets/values/InspireCreativeThinkingandProblem-Solving.svg';
import Card5Icon from 'src/assets/images/about-assets/values/FosteraCultureofContinuousLearning.svg';
import Card6Icon from 'src/assets/images/about-assets/values/GenerateaPositiveandLastingImpact.svg';

export interface Item {
  id: number;
  icon: any;
  text: string;
  bullets: string[];
}

export const OUR_VALUES: Item[] = [
  {
    id: 1,
    icon: Card1Icon,
    text: '<span>Celebrate People & Community</span>',
    bullets: [
      'Place people and community at the heart of everything we do',
      'Listen and respond to their needs with kindness and care',
      'Aspire to leave a personal, positive and lasting impact',
    ],
  },
  {
    id: 2,
    icon: Card2Icon,
    text: '<span>Speak with Empathy & Honesty</span>',
    bullets: [
      'Show empathy in every conversation, both within and beyond our team',
      'Encourage open dialogue, active listening, and sincere feedback',
      'Build lasting relationships anchored in trust and mutual respect',
    ],
  },
  {
    id: 3,
    icon: Card3Icon,
    text: '<span>Embrace Perseverance & Flexibility</span>',
    bullets: [
      'Stay true to our mission and values, even in the face of challenges and setbacks',
      'Be adaptable and flexible in our approach, willing to pivot when necessary',
      'Nurture a culture of continuous improvement and personal growth',
    ],
  },
  {
    id: 4,
    icon: Card4Icon,
    text: '<span>Inspire Creative Thinking and Problem-Solving</span>',
    bullets: [
      'Approach problems with courage and fresh ideas',
      'Encourage imagination, innovation, and experimentation',
      'Embrace emerging technologies as catalysts for progress',
    ],
  },
  {
    id: 5,
    icon: Card5Icon,
    text: '<span>Foster a Culture of Continuous Learning</span>',
    bullets: [
      'Cultivate a mindset of growth, curiosity, and discovery',
      'Encourage experimentation, viewing missteps as opportunities to learn',
      'Persistently refine ourselves, our products, and our services',
    ],
  },
  {
    id: 6,
    icon: Card6Icon,
    text: '<span>Generate a Positive and Lasting Impact</span>',
    bullets: [
      'Concentrate on delivering true value and leaving a positive imprint',
      'Be mindful of our environmental and social impact',
      'Act with integrity and be a positive example for others to follow',
    ],
  },
];
