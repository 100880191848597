import React from 'react';
import Slider from 'react-slick';

const sliderSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 8,
  slidesToScroll: 1,
  autoplay: true,
  speed: 250,
  autoplaySpeed: 2500,
  cssEase: 'linear',
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 6,
      },
    },
  ],
};

interface OurClientsIconsCarouselProps {
  data: any[];
}

const OurClientsIconsCarousel = ({ data }: OurClientsIconsCarouselProps) => {
  return (
    <Slider {...sliderSettings}>
      {data.map((icon: any, index: number) => (
        <img key={index} src={icon} alt="client-logo" />
      ))}
    </Slider>
  );
};

export default OurClientsIconsCarousel;
