import React, { MutableRefObject } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';

import './OurServices.scss';

import ServicesMobileCarousel from './Carousel/ServicesCarousel';

const WhatMakesUsSpecial = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        backgroundSize: isMobile ? 'contain' : 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
      }}
      className="clippy-zone special-snap-point"
      ref={innerRef}
    >
      <div className="fixed-element not-fixed">
        <div className="flexbox">
          <h2 className="capitalised title">OUR SERVICES</h2>

          {isMobile ? (
            <div className="services-card-wrapper">
              <ServicesMobileCarousel />
            </div>
          ) : (
            <div className="services-card-wrapper">
              <div className="services-row">
                <div className="services-card">
                  <h4 className="card-title">Digital Strategy & Advisory</h4>
                  <div className="card-content">
                    <ul className="paragraph-3">
                      <li>Identifying business objectives</li>
                      <li>Defining target audience & segments</li>
                      <li>Elaborating a digital roadmap</li>
                      <li>Conducting market analysis</li>
                      <li>Guidance on digital transformation</li>
                      <li>Igniting digital growth & innovation</li>
                    </ul>
                  </div>
                </div>
                <div className="services-card">
                  <h4 className="card-title">Project Management & Product Owning</h4>
                  <div className="card-content">
                    <ul className="paragraph-3">
                      <li>Planning & executing projects</li>
                      <li>Stakeholder management</li>
                      <li>Identifying & managing project risks</li>
                      <li>Modern, transparent planning & implementation</li>
                      <li>Implementing & cultivating agile & lean practices</li>
                    </ul>
                  </div>
                </div>
                <div className="services-card last-card">
                  <h4 className="card-title">Branding, Marketing & Community</h4>
                  <div className="card-content">
                    <ul className="paragraph-3">
                      <li>Developing a brand strategy</li>
                      <li>Delivering unique marketing campaigns</li>
                      <li>Creating engaging content</li>
                      <li>Fostering community engagement</li>
                      <li>Tracking & improving campaign performance</li>
                      <li>Managing online reputation</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="services-row">
                <div className="services-card">
                  <h4 className="card-title">Interfaces & Automation</h4>
                  <div className="card-content">
                    <ul className="paragraph-3">
                      <li>Integration of tools & platforms</li>
                      <li>Data integration, harmonization & synchronization</li>
                      <li>Custom API development</li>
                      <li>Integration of cloud-based tools & services</li>
                      <li>Custom integrations, workflows & automation</li>
                    </ul>
                  </div>
                </div>
                <div className="services-card">
                  <h4 className="card-title">Design & User Experience</h4>
                  <div className="card-content">
                    <ul className="paragraph-3">
                      <li>Information Architecture & Content Strategy</li>
                      <li>User research & Usability testing</li>
                      <li>Designing intuitive interfaces</li>
                      <li>Creating engaging visuals</li>
                      <li>Developing wireframes & prototypes</li>
                      <li>A/B testing & Interaction design</li>
                    </ul>
                  </div>
                </div>
                <div className="services-card last-card">
                  <h4 className="card-title">Data & Business Intelligence</h4>
                  <div className="paragraph-2 card-content">
                    <ul className="paragraph-3">
                      <li>Dashboards & data visualization</li>
                      <li>Analyzing data insights and drive decisions</li>
                      <li>Customized & automated reportings</li>
                      <li>Deployment of machine learning & predictive analytics</li>
                      <li>Data warehousing & ETL</li>
                      <li>Optimizing database performance</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="services-row last-row">
                <div className="services-card">
                  <h4 className="card-title">Full-Stack-Development</h4>
                  <div className="card-content">
                    <ul className="paragraph-3">
                      <li>Complete tech stack evaluation</li>
                      <li>Creating scalable web- & mobile applications</li>
                      <li>Building responsive user interfaces</li>
                      <li>Developing & integrating secure APIs</li>
                      <li>Implementing cloud infrastructure & DevOps</li>
                    </ul>
                  </div>
                </div>
                <div className="services-card last-card">
                  <h4 className="card-title">Artificial Intelligence & Machine Learning</h4>
                  <div className="card-content">
                    <ul className="paragraph-3">
                      <li>Custom AI solutions for enhanced decision-making</li>
                      <li>Integrating chatbots, voice assistants, and NLP</li>
                      <li>Personalized recommendations and smart interactions</li>
                      <li>Streamlining processes with intelligent automation</li>
                      <li>Building robust, data-driven algorithms</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WhatMakesUsSpecial;
