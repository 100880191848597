import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';

import ContactForm from 'src/components/shared/ContactForm/ContactForm';
import ExtraTabs from 'src/components/about/WhatMakesUsSpecial/Tabs/Tabs';
import Button from 'src/components/shared/Button/Button';
import Footer, { FooterType } from 'src/components/shared/Footer/Footer';

import theTeam from 'src/assets/images/about-assets/oliver.png';

import family from 'src/assets/images/about-assets/ourway/family.svg';
import trainings from 'src/assets/images/about-assets/ourway/trainings.svg';
import open from 'src/assets/images/about-assets/ourway/open.svg';
import exciting from 'src/assets/images/about-assets/ourway/exciting.svg';
import bePart from 'src/assets/images/about-assets/ourway/be-part.svg';
import options from 'src/assets/images/about-assets/ourway/options.svg';
import shape from 'src/assets/images/about-assets/ourway/shape.svg';
import community from 'src/assets/images/about-assets/ourway/community.svg';

import ExpandableCards from './ExpandableCards';
import { OUR_WAY_OF_WORKING } from 'src/assets/data/our-way-of-working';

import './OurWayOfWorking.scss';

const OurWayOfWorking = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  const isMobile = useIsMobile();

  const [footerType, setFooterType] = useState<FooterType>(FooterType.NO_FOOTER);

  useEffect(() => {
    setFooterType(FooterType.WITH_LINKS);
  }, [footerType]);

  return (
    <div className="what-special fixed-container clippy-zone our-way special-snap-point" ref={innerRef}>
      <div className="fixed-element not-fixed">
        <div className="flexbox">
          {isMobile ? (
            <ExpandableCards data={OUR_WAY_OF_WORKING} />
          ) : (
            <div className="tab-content">
              <h2 className="capitalised">
                Our way of <br />
                working
              </h2>
              <ExtraTabs data={OUR_WAY_OF_WORKING} />
            </div>
          )}
          <div className="join-the-team-wrapper">
            <div className="join-the-team team-mesh">
              <img className="team-image" src={theTeam} alt="icon" />
              <div className="join-the-team-content">
                <h2 className="capitalised mobile">
                  Join the <br />
                  Livorit Team
                </h2>
                <div className="paragraph-3">
                  Are you prepared to join a team that fuels passion, accelerates innovation, and celebrates success?
                </div>
                <div className="paragraph-3">
                  We cultivate a collaborative work atmosphere that prioritizes culture, authenticity, excellence, and
                  ambition. Our employees are encouraged to grow and evolve both personally and professionally, as we
                  value a healthy work-life balance complemented by the flexibility required to flourish.
                </div>
                <div className="paragraph-3 m-bot-45">
                  We seek enthusiastic individuals who are passionate about technology, people, and purpose, and possess
                  a tireless thirst for knowledge and growth.
                </div>
                <div className="paragraph-3 loveit-liveit">
                  <span className="with-heart">Love it. Live it. Livorit. ❤</span>
                </div>
              </div>
            </div>
          </div>
          <div className="our-way-ico-wrapper">
            <div className="our-way-content">
              <div className="our-way-content-element">
                <img className="our-way-ico" src={family} alt="icon" />
                <div className="paragraph-2">Family Friendly Working Models</div>
              </div>
              <div className="our-way-content-element">
                <img className="our-way-ico" src={trainings} alt="icon" />
                <div className="paragraph-2">Education & Career Growth</div>
              </div>
              <div className="our-way-content-element">
                <img className="our-way-ico" src={open} alt="icon" />
                <div className="paragraph-2">Flexible Hours & Remote Work</div>
              </div>
              <div className="our-way-content-element">
                <img className="our-way-ico" src={exciting} alt="icon" />
                <div className="paragraph-2">Exciting Team Events & Trips</div>
              </div>
            </div>
            <div className="our-way-content">
              <div className="our-way-content-element">
                <img className="our-way-ico" src={bePart} alt="icon" />
                <div className="paragraph-2">Donations & Good Causes</div>
              </div>
              <div className="our-way-content-element">
                <img className="our-way-ico" src={community} alt="icon" />
                <div className="paragraph-2">Community & Dog Friendly Office</div>
              </div>
              <div className="our-way-content-element">
                <img className="our-way-ico" src={shape} alt="icon" />
                <div className="paragraph-2">Shape Your Responsibility Role</div>
              </div>
              <div className="our-way-content-element">
                <img className="our-way-ico" src={options} alt="icon" />
                <div className="paragraph-2">Employee Share Plans</div>
              </div>{' '}
            </div>
          </div>
          <div className="form-section">
            <div className="title-column">
              <h3>
                Life is short. Work at a <br /> place where you love <br /> your job.
              </h3>
            </div>
            <div id="form-column" className="form-column">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <Footer type={footerType} rootClassName="footer-container homepage-footer" />
    </div>
  );
};

export default OurWayOfWorking;
