import React, { MutableRefObject, useRef } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';

import SectionHeader from './SectionHeader/SectionHeader';
import OurTeam from './OurTeam';
import OurValues from './OurValues';

import './KeyPlayers.scss';

const ProjectsAndTeam = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  const OurValuesRef = useRef(null);
  const isMobile = useIsMobile();

  return (
    <div className="projects-and-team-content fixed-container snap-point" ref={innerRef}>
      <div className="flexbox">
        {!isMobile && <SectionHeader />}
        <div className="content-wrapper no-padding-top">
          <OurTeam />
          <OurValues innerRef={OurValuesRef} />
        </div>
      </div>
    </div>
  );
};

export default ProjectsAndTeam;
