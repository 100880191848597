import { useLayoutEffect, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export const useSize = (target) => {
  const [size, setSize] = useState<any>();

  useLayoutEffect(() => {
    setSize(target?.current?.getBoundingClientRect() ?? 0);
  }, [target]);

  // @ts-ignore
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};
