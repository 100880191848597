import React, { MutableRefObject } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';

import SectionHeader from './SectionHeader/SectionHeader';
import OurLatestProjectsCarousel from './OurLatestProjectsCarousel';
import OurClientsIconsCarousel from './OurClientsIconsCarousel';
import SliderPrimary from 'src/components/shared/SliderPrimary/Slider';
import OurTeam from './OurTeam';

import { OUR_PROJECTS } from 'src/assets/data/our-projects';
import './ProjectsAndTeam.scss';

export interface SlideData {
  id: number;
  text: string;
  modalData: string;
}
export interface Client {
  id: number;
  name: string;
  statement: string;
  testimonial: string;
  testimonialPerson: string;
  testimonialPersonPosition: string;
  keyPractices: string[];
  icon: any;
}

const ProjectsAndTeam = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  const isMobile = useIsMobile();
  const projectsIcons = OUR_PROJECTS.map((c) => c.logo);
  const highlightedProjects = OUR_PROJECTS.filter((p) => p.name !== '');

  return (
    <div className="projects-and-team-content fixed-container snap-point" ref={innerRef}>
      <div className="flexbox">
        {!isMobile && <SectionHeader />}

        <div className="content-wrapper">
          <div className="content-wrapper-title capitalised">
            {isMobile ? (
              <>
                <h2 className="align-right">Livorit creates</h2>
                <h2 className="align-left">Solutions that</h2>
                <h2 className="align-left">Deliver true</h2>
                <h2 className="align-left">Impact</h2>
              </>
            ) : (
              <>
                <h1 className="align-right">Livorit creates</h1>
                <h1 className="align-left">Solutions that</h1>
                <h1 className="align-left">Deliver true impact</h1>
              </>
            )}
          </div>

          <div className="content-wrapper-text paragraph-3">
            In order to craft a truly exceptional solution, it is essential to consider multiple dimensions, including
            design, functionality, market appeal, and user needs. We carefully balance all of these factors to deliver a
            customized and well performing solution for our customers of all industries and sizes.
          </div>
        </div>
        <div className="content-wrapper slider-wrapper">
          <div className="our-clients-icons">
            <OurClientsIconsCarousel data={projectsIcons} />
          </div>
        </div>
        <div className="content-wrapper no-padding-top">
          <div className="our-latest-projects">
            <h2 className="capitalised">Our latest projects</h2>
            <SliderPrimary />
          </div>

          <OurTeam />
        </div>
      </div>
    </div>
  );
};

export default ProjectsAndTeam;
