import React, { useEffect, useMemo, useRef, useState } from 'react';

import { getSeoData } from 'src/SEO/seo.helpers';

import Header from 'src/components/shared/Header/Header';

import Hero from 'src/components/about/Hero/Hero';
import Basics from 'src/components/about/Basics/Basics';
import Ourway from 'src/components/about/WhatMakesUsSpecial/OurWayOfWorking';
import KeyPlayers from 'src/components/about/KeyPlayers/KeyPlayers';
import { Helmet } from 'react-helmet';

import { useLocation } from 'react-router-dom';
import { useScroll } from 'framer-motion';
import { useSize } from 'src/hooks/useSize';
import { useIsMobile } from 'src/hooks/useIsMobile';

import 'src/components/about/about.scss';

const Home = () => {
  const { scrollY } = useScroll();
  const location = useLocation();
  const isMobile = useIsMobile();

  const heroRef = useRef(null);
  const basicsRef = useRef(null);
  const keyPlayersRef = useRef(null);
  const ourWayOfWorkingRef = useRef(null);

  const heroSize = useSize(heroRef);
  const basicSize = useSize(basicsRef);
  const keyPlayersSize = useSize(keyPlayersRef);
  const ourWayOfWorkingSize = useSize(ourWayOfWorkingRef);

  const [darkHeaderMenuIcon, setDarkHeaderMenuIcon] = useState(true);

  const seoData = useMemo(() => {
    return getSeoData(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const ioBasics = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.classList.toggle('basics-in-view', entry.isIntersecting);
        });
      },
      { rootMargin: '0px' }
    );
    // Declares what to observe, and observes its properties.
    const basicsElList = document.querySelectorAll('.basics-observed');
    basicsElList.forEach((el) => {
      ioBasics.observe(el);
    });
    const io = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.classList.toggle('about-in-view', entry.isIntersecting);
        });
      },
      { rootMargin: '0px' }
    );

    // Declares what to observe, and observes its properties.
    const boxElList = document.querySelectorAll('.about-observed');
    boxElList.forEach((el) => {
      io.observe(el);
    });
    //Parallax ends
    //Scrolling starts

    function updateOnScroll() {
      const scroll = scrollY.get();
      const heroOverlay = heroSize?.height - 60;
      const basicsOverlay = heroSize?.height + basicSize?.height + (isMobile ? 200 : 520);
      const keyPlayersOverlay = heroSize?.height + basicSize?.height + keyPlayersSize?.height + (isMobile ? 220 : 520);
      const ourWayOfWorkingOverlay =
        heroSize?.height +
        basicSize?.height +
        keyPlayersSize?.height +
        ourWayOfWorkingSize?.height +
        (isMobile ? 6000 : 4000);

      if (scroll >= heroOverlay && scroll < basicsOverlay) {
        setDarkHeaderMenuIcon(false);
      } else if (scroll >= basicsOverlay && scroll < keyPlayersOverlay) {
        setDarkHeaderMenuIcon(true);
      } else if (scroll >= keyPlayersOverlay && scroll < ourWayOfWorkingOverlay) {
        setDarkHeaderMenuIcon(false);
      } else {
        setDarkHeaderMenuIcon(true);
      }
    }
    const unsubscribeScroll = scrollY.onChange(updateOnScroll);
    return () => {
      unsubscribeScroll();
      io.disconnect();
    };
  }, [heroSize, basicSize, keyPlayersSize, ourWayOfWorkingSize, scrollY, isMobile]);

  return (
    <div className="about-page">
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{seoData.title}</title>
          <meta name="description" content={seoData.metaDescription} />
        </Helmet>
        <Header dark={darkHeaderMenuIcon} />
        <Hero innerRef={heroRef} />
      </div>
      <div className="basics-wrapper">
        {/*<div className="basics-observed basics-top"></div>*/}
        <div className="basics-observed basics-bottom"></div>
        <div className="basics-content mesh-blue">
          <Basics innerRef={basicsRef} />
        </div>
      </div>
      <div className="livorit-solutions">
        <div className="about-observed"></div>
        <KeyPlayers innerRef={keyPlayersRef} />
      </div>
      <Ourway innerRef={ourWayOfWorkingRef} />
    </div>
  );
};

export default Home;
