import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';
import classnames from 'classnames';

import { motion } from 'framer-motion';

import ChevronDownIcon from 'src/assets/images/chevron-down-blue.svg';

import { OUR_VALUES } from 'src/assets/data/our-values';
import { WHAT_YOU_CAN_EXPECT_FROM_US } from 'src/assets/data/what-you-can-expect-from-us';

import './KeyPlayers';
import './OurValues.scss';

const OurValues = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  const isMobile = useIsMobile();
  const [expandedIndex, setExpandedIndex] = useState<number | undefined>(undefined);

  const expandCard = useCallback(
    (index: number) => {
      if (expandedIndex === index) {
        setExpandedIndex(undefined);
      } else {
        setExpandedIndex(index);
      }
    },
    [expandedIndex]
  );

  const MobileComponent = () => (
    <div className="what-you-can-expect-from-us-cards-mobile">
      {OUR_VALUES.map((item, index) => (
        <div key={index} className="card-item">
          <div className="card-item-content">
            <img src={item.icon} alt="icon" />

            <div className="paragraph-3" dangerouslySetInnerHTML={{ __html: item.text }} />

            <img
              className={classnames('chevron-icon', {
                expanded: expandedIndex === index,
              })}
              src={ChevronDownIcon}
              alt="chevron"
              onClick={() => {
                expandCard(index);
              }}
            />
          </div>

          <div
            className={classnames('card-item-content-expanded', {
              show: expandedIndex === index,
            })}
          >
            <ul>
              {item.bullets.map((bullets, index) => (
                <li key={index} className="paragraph-4">
                  {bullets}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );

  const DesktopComponent = () => (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
      className="what-you-can-expect-from-us-cards-desktop"
    >
      {OUR_VALUES.map((item, index) => (
        <div key={item.id} className="card-item">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={item.icon} alt="icon" />

              <div className="paragraph-2" dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>

            <div className="flip-card-back">
              <ul>
                {item.bullets.map((bullets, index) => (
                  <li key={index} className="paragraph-4">
                    {bullets}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </motion.div>
  );

  const WhyOurTeamCards = useMemo(() => {
    return isMobile ? MobileComponent() : DesktopComponent();
  }, [isMobile, expandedIndex]);

  return (
    <div className="what-you-can-expect-from-us-content our-values-content">
      <div className="what-you-can-expect-from-us-content-text">
        <h2 className="capitalised">Our values</h2>
      </div>
      {WhyOurTeamCards}
    </div>
  );
};

export default OurValues;
