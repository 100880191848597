import Card1 from 'src/assets/images/why-our-team/card1.svg';
import Card2 from 'src/assets/images/why-our-team/card2.svg';
import Card3 from 'src/assets/images/why-our-team/card3.svg';

export interface Item {
  id: number;
  icon: any;
  title: string;
  text: string;
}

export const WHY_OUR_TEAM: Item[] = [
  {
    id: 1,
    icon: Card1,
    title: '<span>Dedicated<br/>Communication</span>',
    text: 'Expect to be understood, informed, and taken along in a smooth experience from start to finish.',
  },
  {
    id: 2,
    icon: Card2,
    title: '<span>Always Learning,<br/>Always Growing</span>',
    text: 'Our team is highly educated but constantly thirsty for new knowledge and eager to be pioneers in our field.',
  },
  {
    id: 3,
    icon: Card3,
    title: '<span>Planning Meets<br/>Execution</span>',
    text: 'Igniting growth through reliable identification of potentials and precise alignment of forces. We also take care of the details.',
  },
];
