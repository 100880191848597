import React from 'react';
import AliceCarousel from 'react-alice-carousel';

const items = [
  <div key={1} className="item" data-value="1">
    <div className="services-card">
      <h4 className="card-title">Digital Strategy & Advisory</h4>
      <div className="card-content">
        <ul className="paragraph-3">
          <li>Identifying business objectives</li>
          <li>Defining target audience & market segments</li>
          <li>Elaborating a digital roadmap</li>
          <li>Conducting market analysis</li>
          <li>Guidance on digital transformation</li>
          <li>Igniting digital growth & innovation</li>
        </ul>
      </div>
    </div>
  </div>,
  <div key={2} className="item" data-value="2">
    <div className="services-card">
      <h4 className="card-title">Project Management & Product Owning</h4>
      <div className="card-content">
        <ul className="paragraph-3">
          <li>Planning & executing projects</li>
          <li>Stakeholder management</li>
          <li>Identifying & managing project risks</li>
          <li>Modern, transparent planning & implementation</li>
          <li>Implementing & cultivating agile & lean practices</li>
        </ul>
      </div>
    </div>
  </div>,
  <div key={3} className="item" data-value="3">
    <div className="services-card">
      <h4 className="card-title">Branding, Marketing & Community</h4>
      <div className="card-content">
        <ul className="paragraph-3">
          <li>Developing a brand strategy</li>
          <li>Delivering unique marketing campaigns</li>
          <li>Creating engaging content</li>
          <li>Fostering community engagement</li>
          <li>Tracking & improving campaign performance</li>
          <li>Managing online reputation</li>
        </ul>
      </div>
    </div>
  </div>,
  <div key={4} className="item" data-value="4">
    <div className="services-card">
      <h4 className="card-title">Interfaces & Automation</h4>
      <div className="card-content">
        <ul className="paragraph-3">
          <li>Integration of tools & platforms</li>
          <li>Data integration, harmonization & synchronization</li>
          <li>Custom API development</li>
          <li>Integration of cloud-based tools & services</li>
          <li>Custom integrations, workflows & automation</li>
        </ul>
      </div>
    </div>
  </div>,
  <div key={5} className="item" data-value="5">
    <div className="services-card">
      <h4 className="card-title">Design & User Experience</h4>
      <div className="card-content">
        <ul className="paragraph-3">
          <li>Information Architecture & Content Strategy</li>
          <li>User research & Usability testing</li>
          <li>Designing intuitive interfaces</li>
          <li>Creating engaging visuals</li>
          <li>Developing wireframes & prototypes</li>
          <li>A/B testing & Interaction design</li>
        </ul>
      </div>
    </div>
  </div>,
  <div key={6} className="item" data-value="6">
    <div className="services-card">
      <h4 className="card-title">Data & Business Intelligence</h4>
      <div className="paragraph-2 card-content">
        <ul className="paragraph-3">
          <li>Dashboards & data visualization</li>
          <li>Analyzing data insights and drive decisions</li>
          <li>Customized & automated reportings</li>
          <li>Deployment of machine learning & predictive analytics</li>
          <li>Data warehousing & ETL</li>
          <li>Optimizing database performance</li>
        </ul>
      </div>
    </div>
  </div>,
  <div key={7} className="item" data-value="7">
    <div className="services-card">
      <h4 className="card-title">Full-Stack-Development</h4>
      <div className="card-content">
        <ul className="paragraph-3">
          <li>Complete tech stack evaluation</li>
          <li>Creating scalable web- & mobile applications</li>
          <li>Building responsive user interfaces</li>
          <li>Developing & integrating secure APIs</li>
          <li>Implementing cloud infrastructure & DevOps</li>
        </ul>
      </div>
    </div>
  </div>,
  <div key={8} className="item" data-value="8">
    <div className="services-card">
      <h4 className="card-title">Artificial Intelligence & Machine Learning</h4>
      <div className="card-content">
        <ul className="paragraph-3">
          <li>Custom AI solutions for enhanced decision-making</li>
          <li>Integrating chatbots, voice assistants, and NLP</li>
          <li>Personalized recommendations and smart interactions</li>
          <li>Streamlining processes with intelligent automation</li>
          <li>Building robust, data-driven algorithms</li>
        </ul>
      </div>
    </div>
  </div>,
];

const BasicsMobileCarousel = () => {
  return <AliceCarousel mouseTracking items={items} disableButtonsControls={true} controlsStrategy="alternate" />;
};

export default BasicsMobileCarousel;
