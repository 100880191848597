import Card1Icon from 'src/assets/images/what-you-can-expect-from-us/card1.svg';
import Card2Icon from 'src/assets/images/what-you-can-expect-from-us/card2.svg';
import Card3Icon from 'src/assets/images/what-you-can-expect-from-us/card3.svg';
import Card4Icon from 'src/assets/images/what-you-can-expect-from-us/card4.svg';
import Card5Icon from 'src/assets/images/what-you-can-expect-from-us/card5.svg';
import Card6Icon from 'src/assets/images/what-you-can-expect-from-us/card6.svg';
import Card7Icon from 'src/assets/images/what-you-can-expect-from-us/card7.svg';
import Card8Icon from 'src/assets/images/what-you-can-expect-from-us/card8.svg';

export interface WhatYouCanExpectFromUsItem {
  id: number;
  icon: any;
  text: string;
  bullets: string[];
}

export const WHAT_YOU_CAN_EXPECT_FROM_US: WhatYouCanExpectFromUsItem[] = [
  {
    id: 1,
    icon: Card1Icon,
    text: '<span>Digital Strategy<br/>& Advisory</span>',
    bullets: [
      'Business goals & objectives',
      'Target audience & segments',
      'Market & competitive analysis',
      'Digital roadmap & plan'
    ]
  },
  {
    id: 2,
    icon: Card2Icon,
    text: '<span>Project Management<br/>& Product Owning</span>',
    bullets: [
      'Planning & Scheduling',
      'Resource Management',
      'Risk & Budget Management',
      'Quality Assurance'
    ]
  },
  {
    id: 3,
    icon: Card3Icon,
    text: '<span>Branding, Marketing<br/>& Community</span>',
    bullets: [
      'Brand strategy',
      'Marketing campaigns',
      'Creative content',
      'Social Media'
    ]
  },
  {
    id: 4,
    icon: Card4Icon,
    text: '<span>Interfaces<br/>& Automation</span>',
    bullets: [
      'Integration software & platforms',
      'Data integration & synchronization',
      'Custom API development',
      'Custom workflow development'
    ]
  },
  {
    id: 5,
    icon: Card5Icon,
    text: '<span>Design<br/>& User Experience</span>',
    bullets: [
      'Creative Design',
      'Engagement Strategy',
      'Information Architecture',
      'User research & testing'
    ]
  },
  {
    id: 6,
    icon: Card6Icon,
    text: '<span>Data<br/>& Intelligence</span>',
    bullets: [
      'Data Science',
      'Business Intelligence',
      'Dashboards & Data visualization',
      'Machine learning & predictive analytics'
    ]
  },
  {
    id: 7,
    icon: Card7Icon,
    text: '<span>Full-Stack-<br/>Development</span>',
    bullets: [
      'Tech Stack Evaluation',
      'Interfaces & Automation',
      'Web & mobile application',
      'Cloud infrastructure & DevOps'
    ]
  },
  {
    id: 8,
    icon: Card8Icon,
    text: '<span>Customized<br/>Solutions</span>',
    bullets: [
      'Tailored digital strategy & software solutions',
      'Custom automation & workflows',
      'Bespoke branding & marketing strategies',
      'Other solutions as per client\'s specific needs',
    ]
  },
];
