import React, { useMemo } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';

import Button from 'src/components/shared/Button/Button';
import Logo from 'src/assets/images/livorit-logo.svg';
import KoljaIcon from 'src/assets/images/footer-icons/kolja.png';
import JulesIcon from 'src/assets/images/footer-icons/julia.png';

import './Footer.scss';

export enum FooterType {
  WITH_LETS_TALK_BUTTON = 'with-lets-talk-button',
  WITH_LINKS = 'with-links',
  ONLY_TEXT = 'only-text',
  NO_FOOTER = 'no-footer',
}

interface FooterProps {
  rootClassName?: string;
  type?: FooterType;
}

const Footer = ({ rootClassName, type = FooterType.WITH_LETS_TALK_BUTTON }: FooterProps) => {
  const isMobile = useIsMobile();

  const FooterComponent = useMemo(() => {
    if (type === FooterType.WITH_LINKS) {
      return WithLinks();
    }

    if (type === FooterType.ONLY_TEXT) {
      return OnlyWithText();
    }

    if (type === FooterType.NO_FOOTER) {
      return null;
    }

    return WithActionButtonIconsAndText();
  }, [type, isMobile, rootClassName]);

  function WithActionButtonIconsAndText() {
    return (
      <div className={`footer-container ${rootClassName ?? ''}`}>
        <div className="footer-content">
          <div className="separator" />

          <div className="box">
            <a href="/">
              <img className="logo" src={Logo} alt="LivoritLogo" />
            </a>
            <div className="right-part">
              <a href="#form-column">
                {!isMobile && (
                  <>
                    <div className="footer-icons-wrapper">
                      <div className={'footer-icons'}>
                        <img className="footer-icons-item" src={KoljaIcon} alt="Team member icon" />
                        <img className="footer-icons-item" src={JulesIcon} alt="Team member icon" />
                      </div>
                      <div className={'right-part-text with-margin-right paragraph-5'}>
                        <div>BERLIN BASED DIGITAL</div>
                        <div>SOLUTIONS STUDIO</div>
                      </div>
                    </div>
                  </>
                )}

                <Button
                  text={`Let's talk`}
                  withArrow={true}
                  margin={'0'}
                  minWidth={'285px'}
                  textTransform={'uppercase'}
                  theme={'transparent'}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function WithLinks() {
    return (
      <div className={`footer-container endpage-footer with-links ${rootClassName ?? ''}`}>
        <div className="footer-content">
          <div className="separator" />

          <div className="box">
            <div className="logo-and-copyright-text-wrapper">
              <a href="/">
                <img className="logo" src={Logo} alt="LivoritLogo" />
              </a>
              <div className="copyright-text paragraph-6">
                Livorit GmbH - Reinhardtstraße 37, 10117 Berlin - +49 (0)3076957235 - Copyright reserved
              </div>
            </div>

            <div className="right-part">
              <div className={'footer-menu paragraph-6'}>
                {/*<span>
                  <a onClick={() => undefined}>Cookies</a>
    </span>*/}

                <span>
                  <a href="/">Home</a>
                </span>
                <span>
                  <a href="/about">About Us</a>
                </span>
                <span>
                  <a href="/services">Services</a>
                </span>
                <span>
                  <a href="/privacy" target={'_blank'}>
                    Privacy Policy
                  </a>
                </span>
                <span>
                  <a href="/legal" target={'_blank'}>
                    Imprint
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function OnlyWithText() {
    return (
      <div className={`footer-container additional-padding-bottom-26 ${rootClassName ?? ''}`}>
        <div className="footer-content no-padding">
          <div className="separator smaller-margin" />

          <div className={'only-text-footer-content paragraph-5'}>
            <div>BERLIN BASED DIGITAL</div>
            <div>SOLUTIONS STUDIO</div>
          </div>
        </div>
      </div>
    );
  }

  return FooterComponent;
};

export default Footer;
