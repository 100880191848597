import FabianIcon from 'src/assets/images/team/fabo.png';
import Julia from 'src/assets/images/team/julia.png';
import ErminIcon from 'src/assets/images/team/ermin.png';
import OliIcon from 'src/assets/images/team/oli.png';
import DuxIcon from 'src/assets/images/team/dux.png';
import Kolja from 'src/assets/images/team/kolja.png';
import JasenkoIcon from 'src/assets/images/team/jasenko.png';
import EniIcon from 'src/assets/images/team/eni.png';
import Eric from 'src/assets/images/team/eric.png';
import RichyIcon from 'src/assets/images/team/richy.png';
import EwaIcon from 'src/assets/images/team/ewa.png';
import FerdiIcon from 'src/assets/images/team/ferdi.png';
import AliIcon from 'src/assets/images/team/question.png';

export interface TeamMember {
  id: number;
  name: string;
  description: string;
  picture: any;
  hoverBg: string;
  role: string;
}

export const OUR_TEAM: TeamMember[] = [
  {
    id: 1,
    name: 'Fabo',
    description:
      'Creative spirit who dives deep into the complexities of human communication and behavior, inspiring his innovative pursuits.',
    picture: FabianIcon,
    hoverBg: '#FF8F1C',
    role: 'CEO & CPO',
  },
  {
    id: 2,
    name: 'Julia',
    description:
      'Her magnetic attraction to make the world a better place empowers her to deal with any challenge and embraces exciting adventures.',
    picture: Julia,
    hoverBg: '#DF1995',
    role: 'Finances',
  },
  {
    id: 3,
    name: 'Richy',
    description:
      'Fascinated by forging meaningful connections, Richy cultivates vibrant communities inspired by innovative ideas.',
    picture: RichyIcon,
    hoverBg: '#F9423A',
    role: 'Head of Marketing',
  },
  {
    id: 13,
    name: 'Ewa',
    description:
      'Her designs tell vibrant stories, drawing from a life full of joy and boundless creativity, captivating hearts.',
    picture: EwaIcon,
    hoverBg: '#475BD8',
    role: 'Graphic Design',
  },
  {
    id: 4,
    name: 'Jasenko',
    description:
      'Crafting user experiences as effortlessly cool as his snowboarding and as fluid as his guitar melodies.',
    picture: JasenkoIcon,
    hoverBg: '#00A9E0',
    role: 'Creative Lead',
  },
  {
    id: 5,
    name: 'Eric',
    description:
      "He captures moments with quiet wit and transforms pixels into stories with a gymnast's finesse and filmmaker's eye. ",
    picture: Eric,
    hoverBg: '#1417AC',
    role: 'Content Creator',
  },
  {
    id: 6,
    name: 'Oli',
    description:
      'Personal growth advocate, balancing logical thinking and clear communication, all in tune with passion for music.',
    picture: OliIcon,
    hoverBg: '#48D597',
    role: 'CEO & COO',
  },
  {
    id: 7,
    name: 'Ermin',
    description:
      'Tech-savvy virtuoso, who can build (or break) any system. He is a true nerd with a contagious passion for technology.',
    picture: ErminIcon,
    hoverBg: '#FFCD00',
    role: 'CTO & Tech PM',
  },
  {
    id: 8,
    name: 'Dux',
    description: 'Tech & engineering enthusiast and problem-solver; also master of all pokemon and original gamer.',
    picture: DuxIcon,
    hoverBg: '#171C8F',
    role: 'Full Stack Dev',
  },
  {
    id: 9,
    name: 'Kolja',
    description:
      'Master of networking & strategy, turning business puzzles into success stories with charm and a bright smile.',
    picture: Kolja,
    hoverBg: '#BA16A3',
    role: 'Business Strategist',
  },
  {
    id: 10,
    name: 'Eni',
    description:
      'A metalhead that explored more than 60 countries, which made it a matter of heart to use his profound data & ml skills to help people.',
    picture: EniIcon,
    hoverBg: '#475BD8',
    role: 'Data Scientist',
  },
  {
    id: 11,
    name: 'Ferdi',
    description:
      'Ferdi thinks like a robot, codes like a pro, but surprises all with his wild guitar skills and funky dance moves.',
    picture: FerdiIcon,
    hoverBg: '#FF6800',
    role: 'Data Engineer',
  },
];

export interface StatisticsItem {
  amount: number | string;
  text: string;
}

export const STATISTICS: StatisticsItem[] = [
  {
    amount: '30+',
    text: 'Talents in our network accross Europe',
  },
  {
    amount: 9,
    text: 'Different nationalities',
  },
  {
    amount: 4,
    text: 'Locations accross Europe',
  },
];
