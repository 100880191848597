import React, { MutableRefObject, useRef } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';

import SectionHeader from './SectionHeader/SectionHeader';

import './ourApproach.scss';

import Discovery from 'src/assets/images/services-assets/images/Discovery.png';
import Development from 'src/assets/images/services-assets/images/Development.png';
import Assembling from 'src/assets/images/services-assets/images/Assembling.png';

const ourApproach = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  const ourApproach = useRef(null);
  const isMobile = useIsMobile();

  return (
    <div className="projects-and-team-content fixed-container our-approach-section" ref={innerRef}>
      <div className="flexbox">
        {!isMobile && <SectionHeader />}
        <div className="content-wrapper no-padding-top">
          <h2 className="capitalised title-margin">Our Approach</h2>
          <p className="paragraph-2">
            We have a proven process for delivering successful projects. From initial discovery to final solution
            development, we work closely with our clients and assemble the best team for each project. We also remain
            flexible and ready to adapt to changing needs.
          </p>
          <div className="approach-wrapper">
            <div className="approach-items">
              <div className="approach-left-item">
                <img className="card-image" src={Discovery} alt="icon" />
              </div>
              <div className="approach-right-item">
                <h4 className="strong block-mobile">Discovery</h4>
                <p className="paragraph-2">
                  We work closely with our clients to understand their ideas, develop a strategy, define goals, and
                  scope out the project.
                </p>
              </div>
            </div>
            <div className="approach-items inversed">
              <div className="approach-left-item">
                <img className="card-image" src={Assembling} alt="icon" />
              </div>
              <div className="approach-right-item">
                <h4 className="strong block-mobile">Assembling</h4>
                <p className="paragraph-2">
                  We carefully select experts with the right skills and experience to fit the specific needs of each
                  project
                </p>
              </div>
            </div>
            <div className="approach-items">
              <div className="approach-left-item">
                <img className="card-image" src={Development} alt="icon" />
              </div>
              <div className="approach-right-item">
                <h4 className="strong block-mobile">Development</h4>
                <p className="paragraph-2">
                  From initial design and concept to testing and beyond, we guide our clients through the entire
                  solution development process..
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ourApproach;
