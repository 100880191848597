import React from 'react';

import Header from 'src/components/shared/Header/Header';

const NoPage = () => {
  return (
    <div className="container">
      <Header />

      <h1>404</h1>
    </div>
  );
};

export default NoPage;
