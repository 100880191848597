import React from 'react';
import { motion } from 'framer-motion';

export interface AnimatingMenuIconProps {
  open: boolean;
  dark: boolean;
  toggleIsOpen: () => void;
}

const blue = '#012584';

const AnimatingMenuIcon = ({ open, dark, toggleIsOpen }: AnimatingMenuIconProps) => {
  const horizontalBarVariants: any = {
    open: {
      width: [null, '4px', '4px', '36px', '36px'],
      height: [null, '4px', '4px', '4px', '4px'],
      rotate: [null, 0, -45, -45, -45],
      backgroundColor: [null, blue, blue, blue, blue],
    },
    close: {
      width: [null, '4px', '4px', '36px', '36px'],
      height: [null, '4px', '4px', '4px', '4px'],
      rotate: [null, 0, 0, 0, 0],
      backgroundColor: [
        blue,
        dark ? blue : '#FEFEFE',
        dark ? blue : '#FEFEFE',
        dark ? blue : '#FEFEFE',
        dark ? blue : '#FEFEFE',
      ],
    },
  };

  const verticalBarVariants: any = {
    open: {
      width: [null, '4px', '4px', '36px'],
      height: [null, '4px', '4px', '4px'],
      rotate: [null, 0, 45, 45],
      backgroundColor: [null, blue, blue, blue],
    },
    close: {
      width: [null, '4px', '4px', '36px'],
      height: [null, '4px', '4px', '4px'],
      rotate: [null, 0, 90, 90],
      backgroundColor: [blue, dark ? blue : '#FEFEFE', dark ? blue : '#FEFEFE', dark ? blue : '#FEFEFE'],
    },
  };

  const dotVariants: any = {
    open: {
      width: [null, '16px', '4px'],
      height: [null, '16px', '4px'],
      backgroundColor: [null, blue, blue],
    },
    close: {
      width: [null, '16px', '4px'],
      height: [null, '16px', '4px'],
      backgroundColor: [blue, dark ? blue : '#FEFEFE', dark ? blue : '#FEFEFE'],
    },
  };

  return (
    <motion.div
      className="animating-menu-icon"
      aria-hidden="true"
      onClick={() => {
        toggleIsOpen();
      }}
    >
      <motion.div
        variants={horizontalBarVariants}
        initial={false}
        animate={open ? 'open' : 'close'}
        transition={{
          duration: 0.5,
          type: 'tween',
          ease: [0.66, 0, 0.34, 1],
          times: [0, 0.36, 0.5, 0.99, 1],
        }}
        className="part1"
      />
      <motion.div
        variants={verticalBarVariants}
        initial={false}
        animate={open ? 'open' : 'close'}
        transition={{
          duration: 0.5,
          type: 'tween',
          ease: [0.66, 0, 0.34, 1],
          times: [0, 0.36, 0.5, 1],
        }}
        className="part2"
      />
      <motion.div
        variants={dotVariants}
        initial={false}
        animate={open || dark ? 'open' : 'close'}
        transition={{
          duration: 0.5,
          type: 'tween',
          ease: [0.66, 0, 0.34, 1],
          times: [0, 0.5, 0.86],
        }}
        className="part3"
      />
    </motion.div>
  );
};

export interface MenuIconProps {
  isOpen: boolean;
  dark: boolean;
  toggleIsOpen: () => void;
}

export const MenuIcon = ({ isOpen, dark, toggleIsOpen }: MenuIconProps) => {
  const iconVariants = {
    notMoved: {
      top: '42px',
      right: '32px',
      transition: { type: 'tween', duration: 0.15, ease: [0.4, 0, 0.2, 1] },
    },
  };

  return (
    <div className={`menu-icon-container${isOpen ? ' force-zindex' : ''}`}>
      <motion.div initial={'notMoved'} variants={iconVariants} animate={'notMoved'} className="menu-icon-wrapper">
        <AnimatingMenuIcon open={isOpen} dark={dark} toggleIsOpen={toggleIsOpen} />
      </motion.div>
    </div>
  );
};
