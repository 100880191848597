import React, { MutableRefObject } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';

import './Basics.scss';

import squareCircle from 'src/assets/images/about-assets/cards/square-circle.svg';
import stars from 'src/assets/images/about-assets/cards/stars.svg';
import target from 'src/assets/images/about-assets/cards/target.svg';

import BasicsMobileCarousel from './Carousel/BasicsCarousel';

const WhatMakesUsSpecial = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        backgroundSize: isMobile ? 'contain' : 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
      }}
      className="clippy-zone special-snap-point"
      ref={innerRef}
    >
      <div className="fixed-element not-fixed">
        <div className="flexbox">
          <h2 className="capitalised title">Fundamentals</h2>

          {isMobile ? (
            <div className="basic-card-wrapper">
              <BasicsMobileCarousel />
            </div>
          ) : (
            <div className="basic-card-wrapper">
              <div className="basics-card">
                <h3 className="card-title">WHY</h3>
                <div className="paragraph-2 card-content">
                  Empowering users and communities by prioritizing their voices and addressing their needs through
                  intentional digital solutions.
                </div>
                <img className="card-icon" src={squareCircle} alt="icon" />
              </div>
              <div className="basics-card">
                <h3 className="card-title">WHAT</h3>
                <div className="paragraph-2 card-content">
                  Navigating the journey toward digital mastery, offering tailored solutions that propel to triumph and
                  positive impact.
                </div>
                <img className="card-icon" src={stars} alt="icon" />
              </div>
              <div className="basics-card">
                <h3 className="card-title">HOW</h3>
                <div className="paragraph-2 card-content">
                  Working hard, thinking fast, and taking precise actions to make sure that we get things right, for the
                  user and the client.
                </div>
                <img className="card-icon" src={target} alt="icon" />
              </div>
            </div>
          )}
          <div className="shaping-card">
            <div className="post-title">
              {isMobile ? (
                <h2 className="capitalised">Shaping the digital space</h2>
              ) : (
                <h2 className="capitalised">
                  Shaping <br /> the digital <br /> space
                </h2>
              )}
            </div>
            <div className="card paragraph-2">
              We envision a world where the infinite potential of this ever-evolving space has a profound impact on
              people&apos;s lives. By fostering innovation and nurturing connections, we motivate and empower people to
              seize the endless opportunities this fascinating landscape presents.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatMakesUsSpecial;
