import CariadIcon from 'src/assets/images/our-clients/cariad.svg';
import GolemIcon from 'src/assets/images/our-clients/golem.svg';
import SenacorIcon from 'src/assets/images/our-clients/senacor.svg';
import ThalestIcon from 'src/assets/images/our-clients/thalest.svg';
import ArtecomIcon from 'src/assets/images/our-clients/artecom.svg';
import DADBIcon from 'src/assets/images/our-clients/DADB.svg';
import DiconiumIcon from 'src/assets/images/our-clients/diconium.svg';
import CarsIcon from 'src/assets/images/our-clients/cars.svg';
import SoverosIcon from 'src/assets/images/our-clients/soveros.svg';
import PMIcon from 'src/assets/images/our-clients/pm.svg';

export interface ProjectItem {
  id: number;
  name: string;
  statement: string;
  testimonial: string;
  testimonialPerson: string;
  testimonialPersonPosition: string;
  keyPractices: string[];
  logo: any;
}

export const OUR_PROJECTS: ProjectItem[] = [
  {
    id: 1,
    name: 'CARIAD',
    statement:
      'We helped the client with the deployment and management of a centralized knowledge base and streamlining their existing processes.',
    keyPractices: [
      'DevOps Strategy',
      'Content-Lifecycle-Management',
      'Data Migration',
      'Automation & Interfaces',
      'Atlassian Tool Environment',
    ],
    testimonial:
      'Remarkable and exceptionally high self-organized value and impact oriented way of working with a very high level of expertise - clear recommendation.',
    testimonialPerson: 'Torsten Flaeming',
    testimonialPersonPosition: 'PM CARIAD SE',
    logo: CariadIcon,
  },
  {
    id: 2,
    name: 'Golem',
    statement: 'We managed the building of a B2B IT-Coaching and -career platform connecting professionals.',
    keyPractices: [
      'Business Development Strategy',
      'Product Owning & Technical MVP',
      'Stakeholder Management',
      'Community Building',
      'Booking & Payments',
    ],
    testimonial:
      'The cooperation is extremely professional and reliable. We are very satisfied. The team is very likeable, bright and committed.',
    testimonialPerson: 'Benjamin Sterbenz',
    testimonialPersonPosition: 'CEO Golem Media GmbH',
    logo: GolemIcon,
  },
  {
    id: 3,
    name: 'Senacor AG',
    statement:
      'We set up, connected and automated a sales pipelines including customized reporting features and dashboards.',
    keyPractices: ['CRM Setup Pipedrive', 'Customized API', 'Individual Reporting', 'Pipeline automations'],
    testimonial:
      'Very pleasant collaboration. We got professional and reliable support. Very responsive, Livorit had solutions for all of our questions. We will definitely ask them for further collaborations.',
    testimonialPerson: 'Kathrin Behre',
    testimonialPersonPosition: 'Business Development Senacor AG',
    logo: SenacorIcon,
  },
  {
    id: 4,
    name: 'Diconium Digital Solutions ',
    statement:
      'Our mission was to build a support- and customer success infrastructure to enable developers to their technical environment.',
    keyPractices: [
      'Information Architecture',
      'User Experience Design',
      'Content-Lifecycle-Management',
      'Community Building',
      'Processes & Data Models',
    ],
    testimonial:
      'This is an extraordinarily professional, competent, cross-functional, highly engaged team. They manage to achieve great quality through effective processes adapted to the type of the project.',
    testimonialPerson: 'ELENA MARTINEZ',
    testimonialPersonPosition: 'SM Digital Solutions GmbH',
    logo: DiconiumIcon,
  },
  {
    id: 5,
    name: 'Thalest Group AG',
    statement:
      'The goal was to develop a custom data model according to given KPIs to create insights from reportings and manage the master data.',
    keyPractices: ['Data Modeling', 'KPI Management', 'Data Migration', 'Dashboard & Reporting'],
    testimonial:
      'Many thanks for the great processing of the solution and ongoing support, we got exactly what we were looking for.',
    testimonialPerson: 'Eric Kempter',
    testimonialPersonPosition: 'PM Thalest Group AG',
    logo: ThalestIcon,
  },
  {
    id: 6,
    name: '',
    statement: '',
    keyPractices: ['', '', '', ''],
    testimonial: '',
    testimonialPerson: '',
    testimonialPersonPosition: '',
    logo: ArtecomIcon,
  },
  {
    id: 7,
    name: '',
    statement: '',
    keyPractices: ['', '', '', ''],
    testimonial: '',
    testimonialPerson: '',
    testimonialPersonPosition: '',
    logo: DADBIcon,
  },
  {
    id: 8,
    name: '',
    statement: '',
    keyPractices: ['', '', '', ''],
    testimonial: '',
    testimonialPerson: '',
    testimonialPersonPosition: '',
    logo: CarsIcon,
  },
  {
    id: 8,
    name: '',
    statement: '',
    keyPractices: ['', '', '', ''],
    testimonial: '',
    testimonialPerson: '',
    testimonialPersonPosition: '',
    logo: SoverosIcon,
  },
  {
    id: 8,
    name: '',
    statement: '',
    keyPractices: ['', '', '', ''],
    testimonial: '',
    testimonialPerson: '',
    testimonialPersonPosition: '',
    logo: PMIcon,
  },
];
