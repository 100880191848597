import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export interface FormState {
  name?: string;
  email?: string;
  message?: string;
  livoritPrivacyPolicyConsent?: boolean;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  message: yup.string().required(),
  livoritPrivacyPolicyConsent: yup.boolean().oneOf([true], 'You must accept Livorit privacy policy'),
});

const useContactForm = () => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const formDefaultValues: FormState = {};

  const contactForm = useForm<FormState>({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = useCallback(
    (data: FormState) => {
      if (isSubmittingForm) {
        return;
      }

      setIsSubmittingForm(true);

      try {
      } catch (error) {
      } finally {
        setTimeout(() => {
          setIsSubmittingForm(false);
          contactForm.reset();
        }, 1000);
      }
    },
    [contactForm.formState.errors, isSubmittingForm]
  );

  return {
    contactForm,
    isSubmittingForm,
    onFormSubmit: onSubmit,
  };
};

export { useContactForm };
