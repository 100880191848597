import React from 'react';
import VideoPlayer from 'react-background-video-player';

interface VideoBackgroundProps {
  video: any;
}

const VideoBackground = ({ video }: VideoBackgroundProps) => {
  return <VideoPlayer src={video} autoPlay={true} muted={true} preload="auto" />;
};

export default VideoBackground;
