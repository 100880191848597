import React, { useMemo } from 'react';
import { Tabs } from 'antd';

import { Item } from 'src/assets/data/our-way-of-working';

import './Tabs.scss';

interface Props {
  data: Item[];
}

const ExtraTabs = ({ data }: Props) => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const tabsItems = useMemo(() => {
    return data.map((item, index) => {
      return {
        key: index.toString(),
        label: <div className="paragraph-3" dangerouslySetInnerHTML={{ __html: item.title }} />,
        children: (
          <div className="white-text paragraph-2">
            <strong dangerouslySetInnerHTML={{ __html: item.title }} />
            <div className="paragraph-3" dangerouslySetInnerHTML={{ __html: item.text }} />
          </div>
        ),
      };
    });
  }, [data]);

  return <Tabs tabPosition={'left'} defaultActiveKey="1" items={tabsItems} onChange={onChange} />;
};

export default ExtraTabs;
