import React, { MutableRefObject } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';

import Herobg from 'src/assets/images/livorit-cube.svg';

import './Hero.scss';
import SectionHeader from '../../landing-page/ProjectsAndTeam/SectionHeader/SectionHeader';

const Hero = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  const isMobile = useIsMobile();

  return (
    <div className="services-hero" ref={innerRef}>
      <div className="hero-content-wrapper">
        <div className="services-header">
          <SectionHeader />
        </div>
        <div className="services-content">
          <h1 className="capitalised services-title">
            CULTIVATING <span>DIGITAL</span> EXCELLENCE
          </h1>

          <div>
            <div className="content-wrapper-text paragraph-2 hero-subheading">
              The best digital solutions arise from ambitious thinkers, are shaped by creative minds and are created by
              persistent engineers. We guide you along with expertise at every stage of your journey.
            </div>
            <img className="herobg" src={Herobg} alt="Livorit Hero Cube Logo" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
