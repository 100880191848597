import React from 'react';

import Header from 'src/components/shared/Header/Header';

const imprint = () => {
  return (
    <div className="legal-container">
      <Header />
      <div className="legal-wrapper">
        <div className="legal-header">
          <h2>IMPRINT</h2>
        </div>
        <div className="legal-content-section last">
          <div className="paragraph-2">
            <div className="address-andinfo">
              <div className="address-column">
                <div className="address-row">
                  <h5>LIVORIT GmbH</h5>
                </div>
                <div className="address-row">
                  <h5>ADRESS</h5>
                  <p>Reinhardtstraße 37, 10117 Berlin</p>
                </div>
                <div className="address-row">
                  <h5>EINTRAGUNG</h5>
                  <p>
                    Am 15.06.2019 Amtgericht Charlottenburg <br /> HRB 207617 B
                  </p>
                </div>
              </div>
              <div className="address-column">
                <div className="address-row">
                  <h5>CONTACT</h5>
                  <p>
                    Telefon:
                    <br />
                    +49 (0)30 76957235 <br />
                    E-Mail: <br />
                    info@livorit.de
                  </p>
                </div>
                <div className="address-row">
                  <h5>VERTRETEN DURCH</h5>
                  <p>
                    Fabian Roth <br /> Oliver Bertermann
                  </p>
                </div>
              </div>
              <div className="address-column">
                <div className="address-row">
                  <h5>UMSATZSTEUER IDENTIFIKATIONSNUMMER</h5>
                  <p>DE325006925</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default imprint;
