import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { getSeoData } from 'src/SEO/seo.helpers';
import Header from 'src/components/shared/Header/Header';
import Imprint from 'src/components/legal/imprint';

import 'src/components/legal/legal.scss';

const Legal = () => {
  const seoData = useMemo(() => {
    return getSeoData(location.pathname);
  }, [location.pathname]);
  return (
    <div className="legal">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.metaDescription} />
      </Helmet>
      <Header />
      <Imprint />
    </div>
  );
};

export default Legal;
