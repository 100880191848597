import React from 'react';
import Logo from 'src/assets/images/livorit-logo-dark.svg';

export const HeaderMenuFooter = () => {
  return (
    <div className={'menu-footer-container'}>
      <div className="menu-footer-content">
        <div className="separator-dark" />

        <div className="box">
          <img className="logo" src={Logo} alt="Livorit Logo" />

          <div className="text paragraph-3">
            <span className="breaktext">Love it. Live it. Livorit. ❤</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMenuFooter;
