import * as React from 'react';
import { useEffect, useRef } from 'react';
import { motion, useCycle } from 'framer-motion';
import { useDimensions } from 'src/hooks/useDimensions';
import { ExternalLink } from 'react-external-link';

import HeaderMenuFooter from './HeaderMenuFooter';
import { MenuIcon } from './MenuIcon';

import './HeaderMenuAnimated.scss';

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 4 + 200}px at 100% 0)`,
    transition: {
      type: 'spring',
      stiffness: 200,
      damping: 100,
    },
  }),
  closed: {
    clipPath: 'circle(1px at 100% 0)',
    transition: {
      type: 'spring',
      stiffness: 200,
      damping: 40,
    },
  },
};

export interface MenuProps {
  dark: boolean;
}

const HeaderMenuAnimated = ({ dark }: MenuProps) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableBodyScroll = () => {
    document.body.style.overflow = 'unset';
  };

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }, [isOpen]);

  return (
    <motion.nav
      className="header-menu-animated"
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={height}
      ref={containerRef}
    >
      <motion.div id="menu-wrapper" variants={sidebar}>
        <div className="menu-header">
          <HeaderMenuFooter />
        </div>
        <div className="menu-content">
          <div className="menu-items">
            <h2 className="capitalised menu-item">
              <a href="/">Home</a>
            </h2>
            <h2 className="capitalised menu-item">
              <a href="/about">ABOUT US</a>
            </h2>
            <h2 className="capitalised menu-item">
              <a href="/services">SERVICES</a>
            </h2>
          </div>
          <div className="paragraph-3 menu-bottom-element">
            <div>+49 (0)3076957235</div>

            <ExternalLink href="https://www.linkedin.com/company/livorit/">Linkedin</ExternalLink>
            <a className="margin-bot-35" href="mailto:info@livorit.de">
              info@livorit.de
            </a>
            <div>Reinhardtstraße 37, 10117 Berlin</div>
          </div>
        </div>
      </motion.div>

      <MenuIcon isOpen={isOpen} dark={dark} toggleIsOpen={toggleOpen} />
    </motion.nav>
  );
};

export default HeaderMenuAnimated;
