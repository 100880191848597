import React, { MutableRefObject } from 'react';

import Herobg from 'src/assets/images/livorit-cube.svg';

import './Hero.scss';
import SectionHeader from '../../landing-page/ProjectsAndTeam/SectionHeader/SectionHeader';

const Hero = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  return (
    <div className="about-hero" ref={innerRef}>
      <div className="hero-content-wrapper">
        <div className="about-header">
          <SectionHeader />
        </div>
        <div className="about-content">
          <h1 className="capitalised about-title">
            WE <span>LIV</span>E F<span>OR IT</span>
          </h1>
          <div>
            <div className="content-wrapper-text paragraph-2 hero-subheading">
              Our company embodies our forward-thinking. We strive for constant improvement, growth, collaboration, and
              have a passion for impactful ideas and challenges.
            </div>
            <img className="herobg" src={Herobg} alt="Cube Logo" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
