import React from 'react';

import Header from 'src/components/shared/Header/Header';

const privacyPolicy = () => {
  return (
    <div className="legal-container">
      <Header />
      <div className="legal-wrapper">
        <div className="legal-header">
          <h5>Datenschutzerklärung</h5>
          <div className="paragraph-4">
            <p>
              Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO),
              ist: www.livorit.de
            </p>
          </div>
        </div>
        <div className="legal-content-section">
          <h4>Ihre Betroffenenrechte</h4>
          <div className="paragraph-4">
            <p>
              Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte
              ausüben:
            </p>
            <ul>
              <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
              <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
              <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
              <li>
                Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht
                löschen dürfen (Art. 18 DSGVO),
              </li>
              <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
              <li>
                Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns
                abgeschlossen haben (Art. 20 DSGVO).
              </li>
            </ul>
            <p>
              Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft
              widerrufen.
            </p>
            <p>
              Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige
              Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle
              zuständige Behörde.
            </p>
            <p>
              Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter:{' '}
              <a href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html">
                https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html
              </a>
              .
            </p>
          </div>
        </div>
        <div className="legal-content-section paragraph-4">
          <h4>Erfassung allgemeiner Informationen beim Besuch unserer Website</h4>
          <h5>Art und Zweck der Verarbeitung:</h5>
          <p>
            Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen
            übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen
            (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen
            Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches.{' '}
          </p>
          <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
          <ul>
            <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
            <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
            <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
            <li>zur Optimierung unserer Website.</li>
          </ul>
          <p>
            Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden
            von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende
            Technik zu optimieren.{' '}
          </p>
          <h5>Rechtsgrundlage und berechtigtes Interesse:</h5>
          <p>
            Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der
            Verbesserung der Stabilität und Funktionalität unserer Website.
          </p>
          <h5>Empfänger:</h5>
          <p>
            Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Webseite
            als Auftragsverarbeiter tätig werden.
          </p>
          <h5>Drittlandtransfer:</h5>
          <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen: </p>
          <p>no</p>

          <h5>Speicherdauer:</h5>
          <p>
            Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist
            für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung
            beendet ist.{' '}
          </p>
          <p>
            {' '}
            Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine
            darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer anonymisiert,
            sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
          </p>
          <p></p>
          <h4>Bereitstellung vorgeschrieben oder erforderlich:</h4>
          <p>
            Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich
            vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
            gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus
            diesem Grund ist ein Widerspruch ausgeschlossen.{' '}
          </p>
          <p></p>
          <h5>Drittlandtransfer:</h5>
          <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen: </p>
          <p>keine</p>
        </div>
        <div className="legal-content-section paragraph-4">
          <h4>Technisch nicht notwendige Cookies</h4>
          <p>
            Des Weiteren setzen wir Cookies ein, um das Angebot auf unserer Website besser auf die Interessen unserer
            Besucher abzustimmen oder auf Basis statistischer Auswertungen allgemein zu verbessern.
          </p>
          <p>
            Welche Anbieter Cookies setzen, entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten
            Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.
          </p>
          <h5>Rechtsgrundlage:</h5>
          <p>Rechtsgrundlage für diese Verarbeitungen ist jeweils Ihre Einwilligung, Art. 6 Abs. 1 lit. a DSGVO.</p>
          <h5>Empfänger:</h5>
          <p>
            Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website
            als Auftragsverarbeiter tätig werden.{' '}
          </p>
          <p>
            Weitere Empfänger entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten
            Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.
          </p>
          <h5>Drittlandtransfer:</h5>
          <p>
            Informationen hierzu entnehmen Sie bitte aus den Auflistungen der einzelnen Darstellungs-, Tracking-,
            Remarketing- und Webanalyse-Anbietern.
          </p>
          <h5>Bereitstellung vorgeschrieben oder erforderlich:</h5>
          <p>
            Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Webbrowser sind regelmäßig
            so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von Cookies jederzeit
            über die Einstellungen Ihres Browsers deaktivieren (siehe Widerruf der Einwilligung).
          </p>
          <p>
            Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren, wenn Sie
            die Verwendung von Cookies deaktiviert haben.
          </p>
          <h5>Widerruf der Einwilligung:</h5>
          <p>Sie können Ihre Einwilligung jederzeit über unser Cookie-Consent-Tool widerrufen. </p>
          <h5>Profiling:</h5>
          <p>
            Inwiefern wir das Verhalten von Websitebesuchern mit pseudonymisierten Nutzerprofilen analysieren, entnehmen
            Sie bitte den unten aufgeführten Informationen zu den eingesetzten Darstellungs-, Tracking-, Remarketing-
            und Webanalyse-Technologien.
          </p>
        </div>
        <div className="legal-content-section paragraph-4">
          <h4>Kontaktformular</h4>
          <h5>Art und Zweck der Verarbeitung:</h5>
          <p>
            Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert.
            Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der
            Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.
          </p>
          <h5>Rechtsgrundlage:</h5>
          <p>
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten
            Interesses (Art. 6 Abs. 1 lit. f DSGVO).
          </p>
          <p>
            Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen.
            Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen
            gespeichert.
          </p>
          <p>
            Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das
            Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).
          </p>
          <h5>Empfänger:</h5>
          <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
          <p></p>
          <h5>Drittlandtransfer:</h5>
          <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen: </p>
          <p>no</p>
          <p>Folgende Datenschutzgarantien liegen vor: </p>
          <p></p>
          <h5>Speicherdauer:</h5>
          <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
          <p>
            Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB
            und löschen Ihre Daten nach Ablauf dieser Fristen.{' '}
          </p>
          <h5>Bereitstellung vorgeschrieben oder erforderlich:</h5>
          <p>
            Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur
            bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
          </p>
        </div>
        <div className="legal-content-section paragraph-4">
          <h4>Verwendung von Google Analytics</h4>
          <p>
            Soweit Sie ihre Einwilligung gegeben haben, wird auf dieser Website Google Analytics eingesetzt, ein
            Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend:
            „Google“). Google Analytics verwendet sog. „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert
            werden und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch das Cookie erzeugten
            Informationen über Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA
            übertragen und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird
            Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen
            wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen
            von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
            zusammengeführt.{' '}
          </p>
          <p>
            Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter{' '}
            <a href="https://www.google.com/analytics/terms/de.html und unter https://policies.google.com/?hl=de">
              https://www.google.com/analytics/terms/de.html und unter https://policies.google.com/?hl=de
            </a>
            .{' '}
          </p>
          <p>
            Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der
            Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der
            Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu
            erbringen.{' '}
          </p>
          <p>
            Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften Daten
            werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist,
            erfolgt automatisch einmal im Monat.
          </p>
          <h5>Widerruf der Einwilligung:</h5>
          <p>
            Sie können das Tracking durch Google Analytics auf unserer Website unterbinden, indem Sie{' '}
            <a>diesen Link anklicken</a>. Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird die
            Erfassung durch Google Analytics für diese Website und für diesen Browser zukünftig verhindert, solange das
            Cookie in Ihrem Browser installiert bleibt.
          </p>
          <p>
            Sie können darüber hinaus die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
            Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
            sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.{' '}
          </p>
          <p>
            Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite
            bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
            verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:{' '}
            <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
              Browser Add On zur Deaktivierung von Google Analytics
            </a>
            .
          </p>
        </div>
        <div className="legal-content-section paragraph-4">
          <h4>Verwendung von Scriptbibliotheken (Google Webfonts)</h4>
          <p>
            Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf
            dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
            nachfolgend „Google“) zur Darstellung von Schriften.
          </p>
          <p>
            Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
            <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in der
            Datenschutzerklärung von Google:{' '}
            <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
          </p>
        </div>
        <div className="legal-content-section paragraph-4">
          <h4>Verwendung von Typekit-Webschriftarten von Adobe Fonts</h4>
          <p>
            Wir setzen Typekit-Webschriftarten von Adobe Fonts zur visuellen Gestaltung unserer Website ein. Adobe Fonts
            ist ein Dienst der Adobe Systems Software Ireland Companies (4-6 Riverwalk, Citywest Business Campus, Dublin
            24, Republic of Ireland; nachfolgend „Adobe“), der uns den Zugriff auf eine Schriftartenbibliothek gewährt.{' '}
          </p>
          <p>
            Zur Einbindung der von uns benutzten Schriftarten muss Ihr Browser eine Verbindung zu einem Server von Adobe
            in den USA aufbauen und die für unsere Website benötigte Schriftart herunterladen. Adobe erhält hierdurch
            die Information, dass von Ihrer IP-Adresse unsere Website aufgerufen wurde.{' '}
          </p>
          <p>
            Weitere Informationen zu Adobe Fonts finden Sie in den Datenschutzhinweisen von Adobe Fonts, die Sie hier
            abrufen können:{' '}
            <a href="https://www.adobe.com/de/privacy/policies/adobe-fonts.html">
              https://www.adobe.com/de/privacy/policies/adobe-fonts.html
            </a>
          </p>
          <p>
            Wenn Ihr Browser Web Fonts nicht unterstützt, oder Sie Ihre Einwilligung nicht erteilen, wird eine
            Standardschrift von Ihrem Computer genutzt.
          </p>
          <h5>Widerruf der Einwilligung:</h5>
          <p>
            Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der
            Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
            wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie
            oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie unsere
            Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
          </p>
        </div>
        <div className="legal-content-section paragraph-4">
          <h4>Verwendung von Google Maps</h4>
          <p>
            Auf dieser Website nutzen wir das Angebot von Google Maps. Google Maps wird von Google LLC, 1600
            Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend „Google“) betrieben. Dadurch können wir
            Ihnen interaktive Karten direkt in der Webseite anzeigen und ermöglichen Ihnen die komfortable Nutzung der
            Karten-Funktion.
            <br />
            Nähere Informationen über die Datenverarbeitung durch Google können Sie den Google-Datenschutzhinweisen
            entnehmen: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>. Dort
            können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern.
          </p>
          <p>
            Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten finden Sie
            hier: <a href="https://www.dataliberation.org/">https://www.dataliberation.org</a>
          </p>
          <p>
            Durch den Besuch der Website erhält Google Informationen, dass Sie die entsprechende Unterseite unserer
            Webseite aufgerufen haben. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das
            Sie eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre
            Daten direkt Ihrem Konto zugeordnet.{' '}
          </p>
          <p>
            Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des
            Buttons bei Google ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der
            Werbung, Marktforschung und/oder bedarfsgerechter Gestaltung seiner Websites. Eine solche Auswertung erfolgt
            insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und um andere
            Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein
            Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google
            richten müssen.{' '}
          </p>
          <h5>Widerruf der Einwilligung:</h5>
          <p>
            Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der
            Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
            wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie
            oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie unsere
            Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
          </p>
        </div>
        <div className="legal-content-section paragraph-4">
          <h4>Google AdWords</h4>
          <p>
            Unsere Website nutzt das Google Conversion-Tracking. Betreibergesellschaft der Dienste von Google AdWords
            ist die Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Sind Sie über eine von Google
            geschaltete Anzeige auf unsere Webseite gelangt, wird von Google Adwords ein Cookie auf Ihrem Rechner
            gesetzt. Das Cookie für Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine von Google geschaltete
            Anzeige klickt.
          </p>
          <p>
            Besucht der Nutzer bestimmte Seiten unserer Website und das Cookie ist noch nicht abgelaufen, können wir und
            Google erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
            Jeder Google AdWords-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Websites von
            AdWords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen
            dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden
            haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit
            einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine
            Informationen, mit denen sich Nutzer persönlich identifizieren lassen.
          </p>
          <p>
            Nähere Informationen über die Datenverarbeitung durch Google können Sie den Google-Datenschutzhinweisen
            entnehmen: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>. Dort
            können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern.
          </p>
          <h5>Widerruf der Einwilligung:</h5>
          <p>
            Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der
            Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
            wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie
            oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie unsere
            Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
          </p>
        </div>
        <div className="legal-content-section paragraph-4">
          <h4>Einsatz von Google Remarketing</h4>
          <p>
            Diese Website verwendet die Remarketing-Funktion der Google Inc. Betreibergesellschaft der Dienste von
            Google Remarketing ist die Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend
            „Google“).
          </p>
          <p>
            Die Funktion dient dazu, Websitebesuchern innerhalb des Google-Werbenetzwerks interessenbezogene
            Werbeanzeigen zu präsentieren. Im Browser des Websitebesuchers wird ein sog. „Cookie“ gespeichert, der es
            ermöglicht, den Besucher wiederzuerkennen, wenn dieser Webseiten aufruft, die dem Werbenetzwerk von Google
            angehören. Auf diesen Seiten können dem Besucher Werbeanzeigen präsentiert werden, die sich auf Inhalte
            beziehen, die der Besucher zuvor auf Webseiten aufgerufen hat, die die Remarketing Funktion von Google
            verwenden.
          </p>
          <p>
            Nähere Informationen über die Datenverarbeitung durch Google können Sie den Google-Datenschutzhinweisen
            entnehmen: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>. Dort
            können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern.{' '}
          </p>
          <h5>Widerruf der Einwilligung:</h5>
          <p>
            Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der
            Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
            wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie
            oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie unsere
            Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
          </p>
        </div>
        <div className="legal-content-section paragraph-4">
          <h4>SSL-Verschlüsselung</h4>
          <p>
            Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik
            entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
          </p>
        </div>
        <div className="legal-content-section paragraph-4">
          <h4>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h4>
          <h5>Einzelfallbezogenes Widerspruchsrecht</h5>
          <p>
            Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
            Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
            (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt
            auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
          </p>
          <p>
            Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir
            können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
            Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen.
          </p>
          <h5>Empfänger eines Widerspruchs</h5>
          <p>info@livorit.de</p>
        </div>
        <div className="legal-content-section paragraph-4 last">
          <h4>Änderung unserer Datenschutzbestimmungen</h4>
          <p>
            Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen
            Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B.
            bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
          </p>
          <h5>Fragen an den Datenschutzbeauftragten</h5>
          <p>
            Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an
            die für den Datenschutz verantwortliche Person in unserer Organisation:
          </p>
          <p>Ermin Bicakcic</p>
          <p>
            <em>
              Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für{' '}
              <a href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/">
                externe Datenschutzbeauftragte
              </a>{' '}
              (Version #2020-09-30).
            </em>
          </p>
        </div>
      </div>
    </div>
  );
};

export default privacyPolicy;
