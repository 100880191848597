import React from 'react';

import Logo from 'src/assets/images/livorit-logo-dark.svg';
import Button from 'src/components/shared/Button/Button';
import { useIsMobile } from 'src/hooks/useIsMobile';

import KoljaIcon from 'src/assets/images/footer-icons/kolja.png';
import JulesIcon from 'src/assets/images/footer-icons/julia.png';

import './SectionHeader.scss';

const SectionHeader = () => {
  const isMobile = useIsMobile();

  return (
    <div className="section-header-content">
      <div className="separator" />

      <div className="box">
        <a href="/">
          <img className="logo" src={Logo} alt="LivoritLogo" />
        </a>
        <div className="right-part">
          <a href="#form-column">
            {!isMobile && (
              <>
                <div className="header-icons-wrapper">
                  <div className={'header-icons'}>
                    <img className="header-icons-item" src={KoljaIcon} alt="Team member icon" />
                    <img className="header-icons-item" src={JulesIcon} alt="Team member icon" />
                  </div>
                  <div className={'right-part-text with-margin-right paragraph-5'}>
                    <div>BERLIN BASED DIGITAL</div>
                    <div>SOLUTIONS STUDIO</div>
                  </div>
                </div>
              </>
            )}
            <Button
              text={`Let's talk`}
              withArrow={true}
              margin={'0'}
              minWidth={'285px'}
              textTransform={'uppercase'}
              theme={'light'}
              overrideClassnames={'header-button'}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SectionHeader;
