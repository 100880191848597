import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';

import ContactForm from 'src/components/shared/ContactForm/ContactForm';
import Footer, { FooterType } from 'src/components/shared/Footer/Footer';

import scalable from 'src/assets/images/services-assets/icons/customized-scalable.svg';
import innovative from 'src/assets/images/services-assets/icons/innovative-forward-thinking.svg';
import endToEnd from 'src/assets/images/services-assets/icons/end-to-end-solutions.svg';
import precise from 'src/assets/images/services-assets/icons/precisely-engineered.svg';
import robust from 'src/assets/images/services-assets/icons/robust-secure.svg';
import seamless from 'src/assets/images/services-assets/icons/seamlessly-integrated.svg';
import customer from 'src/assets/images/services-assets/icons/customer-centric.svg';
import intuitive from 'src/assets/images/services-assets/icons/intuitive-experiences.svg';

import './Toolbox.scss';

const OurWayOfWorking = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  const isMobile = useIsMobile();

  const [footerType, setFooterType] = useState<FooterType>(FooterType.NO_FOOTER);

  useEffect(() => {
    setFooterType(FooterType.WITH_LINKS);
  }, [footerType]);

  return (
    <div className="what-special fixed-container clippy-zone our-way expertise special-snap-point" ref={innerRef}>
      <div className="fixed-element not-fixed">
        <div className="flexbox">
          <h2 className="capitalised">Reliable Solutions</h2>
          {/*
          <div className="paragraph-2 toolbox subheading">A glimpse into our diverse skills and toolbox.</div>
          <div className="toolbox-wrapper">
            <div className="toolbox-content">
              <div className="paragraph-3">Methodologies</div>
              <div className="paragraph-4 toolbox-items">
                <span>Agile development</span>
                <span>Lean startup</span>
                <span>Design thinking</span>
                <span>DevOps</span>
                <span>Rapid Prototyping</span>
                <span>REST</span>
              </div>
            </div>
            <div className="toolbox-content">
              <div className="paragraph-3">Technologies</div>
              <div className="paragraph-4 toolbox-items">
                <span>AWS</span>
                <span>Azure</span>
                <span>Git & Jenkins</span>
                <span>Atlassian</span>
                <span>NLP</span>
                <span>Machine Learning</span>
                <span>Stable Diffusion</span>
              </div>
            </div>
            <div className="toolbox-content">
              <div className="paragraph-3">Languages</div>
              <div className="paragraph-4 toolbox-items">
                <span>Python</span>
                <span>Django</span>
                <span>React.JS</span>
                <span>Vue.JS</span>
                <span>C#</span>
                <span>PHP</span>
                <span>Ruby</span>
                <span>Rust</span>
                <span>Node.JS</span>
                <span>Go</span>
                <span>MongoDB</span>
                <span>ES6</span>
                <span>JS</span>
                <span>Angular</span>
                <span>Python</span>
              </div>
            </div>
            <div className="toolbox-content">
              <div className="paragraph-3">Roles & Skills</div>
              <div className="paragraph-4 toolbox-items">
                <span>PM</span>
                <span>UX Design</span>
                <span>Full-stack Dev</span>
                <span>Product Owner</span>
                <span>Information Architecture</span>
                <span>Data Visualization</span>
                <span>Digital Marketing</span>
                <span>Copywriting</span>
                <span>Data Engineer</span>
              </div>
            </div>
          </div> */}
          <div className="our-way-ico-wrapper services-our-way">
            <div className="our-way-content">
              <div className="our-way-content-element">
                <div className="wrap-img">
                  <img className="our-way-ico" src={scalable} alt="icon" />
                </div>
                <div className="paragraph-2">Customized & scalable</div>
              </div>
              <div className="our-way-content-element">
                <div className="wrap-img">
                  <img className="our-way-ico" src={innovative} alt="icon" />
                </div>
                <div className="paragraph-2">Innovative & forward-thinking</div>
              </div>
              <div className="our-way-content-element">
                <div className="wrap-img">
                  <img className="our-way-ico" src={endToEnd} alt="icon" />
                </div>
                <div className="paragraph-2">End-to-End solutions</div>
              </div>
              <div className="our-way-content-element">
                <div className="wrap-img">
                  <img className="our-way-ico" src={precise} alt="icon" />
                </div>
                <div className="paragraph-2">Precisely Engineered</div>
              </div>
            </div>
            <div className="our-way-content">
              <div className="our-way-content-element">
                <div className="wrap-img">
                  <img className="our-way-ico" src={robust} alt="icon" />
                </div>
                <div className="paragraph-2">Robust & Secure</div>
              </div>
              <div className="our-way-content-element">
                <div className="wrap-img">
                  <img className="our-way-ico" src={seamless} alt="icon" />
                </div>
                <div className="paragraph-2">Seamlessly integrated</div>
              </div>
              <div className="our-way-content-element">
                <div className="wrap-img">
                  <img className="our-way-ico" src={customer} alt="icon" />
                </div>
                <div className="paragraph-2">Customer centric</div>
              </div>
              <div className="our-way-content-element">
                <div className="wrap-img">
                  <img className="our-way-ico" src={intuitive} alt="icon" />
                </div>
                <div className="paragraph-2">Intuitive experiences</div>
              </div>{' '}
            </div>
          </div>
          <div className="form-section">
            <div className="title-column">
              <h3>Cultivate your digital excellence and start your journey today.</h3>
              <div className="paragraph-3">
                <br />
                We&apos;re here to help you navigate the digital landscape and achieve lasting success. Don&apos;t wait
                and get your free consultation!
              </div>
            </div>
            <div id="form-column" className="form-column">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <Footer type={footerType} rootClassName="footer-container homepage-footer" />
    </div>
  );
};

export default OurWayOfWorking;
