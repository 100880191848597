import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { getSeoData } from 'src/SEO/seo.helpers';

import Header from 'src/components/shared/Header/Header';
import PrivacyPolicy from 'src/components/legal/privacy';

import 'src/components/legal/legal.scss';

const Privacy = () => {
  const seoData = useMemo(() => {
    return getSeoData(location.pathname);
  }, [location.pathname]);
  return (
    <div className="legal">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.metaDescription} />
      </Helmet>
      <Header />
      <PrivacyPolicy />
    </div>
  );
};

export default Privacy;
