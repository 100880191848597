import React from 'react';
import { ExternalLink } from 'react-external-link';

import './UnderConstruction.scss';

const UnderConstruction = () => {
  return (
    <div>
      <div className="under-construction-content flexbox">
        <h2>COMING SOON:</h2>
        <h2>A NEW LOOK</h2>
        <h2>FOR LIVORIT</h2>

        <div className="under-construction-content-divider" />

        <div className="under-construction-content-text">
          <div className="paragraph-3">
            We will be launching our new site in the coming weeks. In the <br />
            meantime, follow us on{' '}
            <ExternalLink href="https://www.linkedin.com/company/livorit/">Linkedin</ExternalLink>
          </div>

          <div className="paragraph-3 contact-info">
            <span className="breaktext">+49 (0)3076957235</span>
            <span className="breaktext">Reinhardtstraße 37, 10117 Berlin</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
