import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal';
import './Slider.scss';

// Assets
import { slideData } from 'src/assets/data/primary-slide';
import QuotesIcon from 'src/assets/images/quotes.svg';
import QuotesIconBlue from 'src/assets/images/quotes-blue.svg';
import globe from 'src/assets/icons/globe.svg';
import locationPoint from 'src/assets/icons/location.svg';
import calendar from 'src/assets/icons/calendar.svg';
import sqr from 'src/assets/icons/sqr.svg';
import arrowLeft from 'src/assets/icons/arrow-left-blue.svg';
import arrowRight from 'src/assets/icons/arrow-right-blue.svg';
import plusIconDark from 'src/assets/images/plus-icon-dark.svg';

interface SlideData {
  id: number;
  text: string;
  image: string;
  name: string;
  company: {
    image: string;
    companyname: string;
  };
  title: string;
  tags: string[];
  companyLink: string;
  location: string;
  activeDate: string;
  industry: string;
  qualityScore: number;
  scheduleScore: number;
  communicationScore: number;
  summary: string;
  servicesRendered: string[];
  results: string;
}
const getScoreClass = (score: number): string => {
  if (score === 1) {
    return 'bad';
  } else if (score === 2) {
    return 'enough';
  } else if (score === 3) {
    return 'good';
  } else if (score === 4) {
    return 'very-good';
  } else if (score === 5) {
    return 'excellent';
  } else {
    return '';
  }
};

const getScoreText = (score: number): string => {
  if (score === 1) {
    return 'bad';
  } else if (score === 2) {
    return 'enough';
  } else if (score === 3) {
    return 'good';
  } else if (score === 4) {
    return 'very good';
  } else if (score === 5) {
    return 'excellent';
  } else {
    return '';
  }
};
const Slide: React.FC<{
  data: SlideData;
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  className: string;
}> = ({ data, isOpen, openModal, closeModal, className }) => {
  return (
    <div id={`slide-${data.id}`} className={className}>
      <div className="slide-heading">
        <div className="logo-wrap">
          <img src={data.company.image} alt={data.company.companyname} />
        </div>
      </div>
      <div className="slide-body">
        <img className="quotes-icon" src={QuotesIcon} alt="quotes" />
        <p className="slide-text">{data.text}</p>
        <div className="slide-person">
          <img className="person-image" src={data.image} alt={data.name} />
          <div className="person-info">
            <p>{data.name}</p>
            <p className="company-position">{data.company.companyname}</p>
          </div>
        </div>
        <button className="transparent" onClick={openModal}>
          View Case Study
        </button>
      </div>
      <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel="Modal">
        <button className="close-modal" onClick={closeModal}>
          <img src={plusIconDark} alt="close modal icon" />
        </button>
        <img className="modal-logo" src={data.company.image} alt={data.company.companyname} />
        <h2 className="modal-title">{data.title}</h2>
        <div className="modal-tags">
          {data.tags.map((tag, index) => (
            <div key={index} className="modal-tag">
              {tag}
            </div>
          ))}
        </div>
        <div className="modal-info">
          <div className="modal-info-content">
            <img className="info-icon" src={globe} alt="globe-icon" />
            <a href={data.companyLink} target={'blank'}>
              {data.companyLink.replace(/(^\w+:|^)\/\//, '')}
            </a>
          </div>
          <div className="modal-info-content">
            <img className="info-icon" src={locationPoint} alt="location-icon" />
            <p>{data.location}</p>
          </div>
          <div className="modal-info-content">
            <img className="info-icon" src={calendar} alt="calendar-icon" />
            <p>{data.activeDate}</p>
          </div>
          <div className="modal-info-content">
            <img className="info-icon" src={sqr} alt="square-icon" />
            <p>{data.industry}</p>
          </div>
        </div>
        {/*  
        <div className="modal-boxes">
          <div className="modal-box">
            <p className="box-title">Qualitat</p>
            <div className="score">
              <div className={`score-value ${getScoreClass(data.qualityScore)}`}>
                <div className="score-block"></div>
                <div className="score-block"></div>
                <div className="score-block"></div>
                <div className="score-block"></div>
                <div className="score-block"></div>
              </div>
              <p>{data.qualityScore}/5</p>
            </div>
          </div>
          <div className="modal-box">
            <p className="box-title">Termintrue</p>
            <div className="score">
              <div className={`score-value ${getScoreClass(data.scheduleScore)}`}>
                <div className="score-block"></div>
                <div className="score-block"></div>
                <div className="score-block"></div>
                <div className="score-block"></div>
                <div className="score-block"></div>
              </div>
              <p>{data.scheduleScore}/5</p>
            </div>
          </div>
          <div className="modal-box">
            <p className="box-title">Kommunikation</p>
            <div className="score">
              <div className={`score-value ${getScoreClass(data.communicationScore)}`}>
                <div className="score-block"></div>
                <div className="score-block"></div>
                <div className="score-block"></div>
                <div className="score-block"></div>
                <div className="score-block"></div>
              </div>
              <p>{data.communicationScore}/5</p>
            </div>
          </div>
        </div>*/}
        <div className="modal-body">
          <img className="quotes-blue" src={QuotesIconBlue} alt="quotes" />
          <p className="modal-text">&quot;{data.text}&quot;</p>
          <div className="modal-person">
            <img className="modal-person-image" src={data.image} alt={data.name} />
            <div className="modal-person-info">
              <div className="modal-person-details">
                <p className="modal-person-name">{data.name}</p>
                <p className="modal-company-position">{data.company.companyname}</p>
              </div>
              <img className="modal-quotes-icon" src={QuotesIcon} alt="quotes" />
            </div>
          </div>
        </div>
        <div className="modal-summary">
          <p className="semibold">Summary:</p>
          <p>{data.summary}</p>
        </div>
        <div className="modal-summary">
          <p className="semibold">Services rendered:</p>
          {data.servicesRendered.map((service, index) => (
            <div key={index} className="modal-services">
              <li>{service}</li>
            </div>
          ))}
        </div>
        {data.results && (
          <div className="modal-summary">
            <p className="semibold">Results:</p>
            <p>{data.results}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

const SlickSlider: React.FC = () => {
  const [slides, setSlides] = useState<SlideData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [activeSlideId, setActiveSlideId] = useState<number | null>(null);
  const [isDraggable, setIsDraggable] = useState(true);

  useEffect(() => {
    setSlides(slideData);
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    draggable: isDraggable,
    speed: 500,
    slidesToShow: window.innerWidth >= 1200 ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <img src={arrowRight} alt="Next" />,
    prevArrow: <img src={arrowLeft} alt="Previous" />,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slides.map((slide, slideIndex) => (
          <Slide
            key={slide.id}
            data={slide}
            isOpen={isOpen && activeSlideId === slide.id}
            openModal={() => {
              setIsOpen(true);
              setActiveSlideId(slide.id);
              document.body.classList.add('modal-open');
              setIsDraggable(false);
            }}
            closeModal={() => {
              setIsOpen(false);
              document.body.classList.remove('modal-open');
              setIsDraggable(true);
            }}
            className={`slide`}
          />
        ))}
      </Slider>
    </div>
  );
};

export default SlickSlider;
