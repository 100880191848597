import React, { MutableRefObject, useMemo } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';

import ContactForm from 'src/components/shared/ContactForm/ContactForm';
import ExpandableCards from './ExpandableCards';

import { WHY_OUR_TEAM } from 'src/assets/data/why-our-team';
import { WHY_OUR_SOLUTIONS, Item } from 'src/assets/data/why-our-solutions';

import './WhatMakesUsSpecial.scss';

const WhatMakesUsSpecial = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  const isMobile = useIsMobile();

  const WhyOurTeamCards = useMemo(() => {
    if (isMobile) {
      return <ExpandableCards data={WHY_OUR_TEAM} />;
    }

    return (
      <div className="what-makes-us-special-content-cards-desktop">
        {WHY_OUR_TEAM.map((item) => (
          <div key={item.id} className="what-makes-us-special-card">
            <div className="img-and-title-wrapper">
              <img src={item.icon} alt={item.text} />
              <h5 dangerouslySetInnerHTML={{ __html: item.title }} />
            </div>

            <div className="paragraph-3 description">{item.text}</div>
          </div>
        ))}
      </div>
    );
  }, [isMobile]);

  const WhyOurSolutionsCards = useMemo(() => {
    if (isMobile) {
      return <ExpandableCards data={WHY_OUR_SOLUTIONS} />;
    }

    return (
      <div className="what-makes-us-special-content-cards-desktop">
        {WHY_OUR_SOLUTIONS.map((item) => (
          <div key={item.id} className="what-makes-us-special-card">
            <div className="img-and-title-wrapper">
              <img src={item.icon} alt={item.text} />
              <h5 dangerouslySetInnerHTML={{ __html: item.title }} />
            </div>

            <div className="paragraph-3 description">{item.text}</div>
          </div>
        ))}
      </div>
    );
  }, [isMobile]);

  return (
    <div className="what-special fixed-container clippy-zone gradient-blue special-snap-point" ref={innerRef}>
      <div className="fixed-element not-fixed">
        <div className="flexbox what-makes-us-special-content">
          <h2 className="capitalised">Why our team</h2>

          {WhyOurTeamCards}

          <h2 className="capitalised">Why our solutions</h2>

          {WhyOurSolutionsCards}

          <div className="form-section">
            <div className="title-column">
              <h3>
                We understand your aspirations. Let’s explore <br /> them together!
              </h3>
            </div>

            <div id="form-column" className="form-column">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatMakesUsSpecial;
