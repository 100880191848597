export interface Item {
  id: number;
  title: string;
  text: string;
}

export const OUR_WAY_OF_WORKING: Item[] = [
  {
    id: 1,
    title: '<strong>Courage for Truth</strong>',
    text: 'At Livorit we value authenticity, honesty, and transparency in everything we do. We are committed to building open and honest relationships with clients, partners, and colleagues.',
  },
  {
    id: 2,
    title: '<strong>Logic meets Creativity</strong>',
    text: 'Our team strikes a balance between systematic approaches, a strong dose of creativity and open-mindedness. By blending these elements, we craft memorable and effective digital solutions.',
  },
  {
    id: 3,
    title: '<strong>Igniting Emergence</strong>',
    text: 'Diverse perspectives act as a powerful catalyst for creativity and innovation. By uniting ideas and talent, we achieve results that surpass the sum of individual contributions.',
  },
  {
    id: 4,
    title: '<strong>Dedicated Commitment</strong>',
    text: 'We fully invest in understanding our clients ideas and challenges, adopting their perspectives to passionately pursue the most fitting solutions.',
  },
];
