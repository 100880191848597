import React, { useEffect, useMemo, useRef, useState } from 'react';

import { getSeoData } from 'src/SEO/seo.helpers';

import Header from 'src/components/shared/Header/Header';

import Hero from 'src/components/services/Hero/Hero';
import OurServices from 'src/components/services/OurServices/OurServices';
import OurApproach from 'src/components/services/OurApproach/ourApproach';
import Toolbox from 'src/components/services/Toolbox/Toolbox';
import { Helmet } from 'react-helmet';

import { useLocation } from 'react-router-dom';
import { useScroll } from 'framer-motion';
import { useSize } from 'src/hooks/useSize';
import { useIsMobile } from 'src/hooks/useIsMobile';

import 'src/components/services/services.scss';

const Home = () => {
  const { scrollY } = useScroll();
  const location = useLocation();
  const isMobile = useIsMobile();

  const heroRef = useRef(null);
  const servicessRef = useRef(null);
  const ourApproachRef = useRef(null);
  const ourWayOfWorkingRef = useRef(null);

  const heroSize = useSize(heroRef);
  const servicesSize = useSize(servicessRef);
  const ourApproachSize = useSize(ourApproachRef);
  const ourWayOfWorkingSize = useSize(ourWayOfWorkingRef);

  const [darkHeaderMenuIcon, setDarkHeaderMenuIcon] = useState(true);

  const seoData = useMemo(() => {
    return getSeoData(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const ioservices = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.classList.toggle('services-in-view', entry.isIntersecting);
        });
      },
      { rootMargin: '0px' }
    );
    // Declares what to observe, and observes its properties.
    const servicesElList = document.querySelectorAll('.services-observed');
    servicesElList.forEach((el) => {
      ioservices.observe(el);
    });

    // PARALAX APPROACH
    const io = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.classList.toggle('approach-in-view', entry.isIntersecting);
        });
      },
      { rootMargin: '0px' }
    );

    // Declares what to observe, and observes its properties.
    const boxElList = document.querySelectorAll('.approach-observed');
    boxElList.forEach((el) => {
      io.observe(el);
    });
    //Parallax ends
    //Scrolling starts

    function updateOnScroll() {
      const scroll = scrollY.get();
      const heroOverlay = heroSize?.height - 60;
      const servicessOverlay = heroSize?.height + servicesSize?.height + (isMobile ? 0 : 340);
      const ourApproachOverlay =
        heroSize?.height + servicesSize?.height + ourApproachSize?.height + (isMobile ? 120 : 660);
      const ourWayOfWorkingOverlay =
        heroSize?.height +
        servicesSize?.height +
        ourApproachSize?.height +
        ourWayOfWorkingSize?.height +
        (isMobile ? 6000 : 4000);

      if (scroll >= heroOverlay && scroll < servicessOverlay) {
        setDarkHeaderMenuIcon(false);
      } else if (scroll >= servicessOverlay && scroll < ourApproachOverlay) {
        setDarkHeaderMenuIcon(true);
      } else if (scroll >= ourApproachOverlay && scroll < ourWayOfWorkingOverlay) {
        setDarkHeaderMenuIcon(false);
      } else {
        setDarkHeaderMenuIcon(true);
      }
    }
    const unsubscribeScroll = scrollY.onChange(updateOnScroll);
    return () => {
      unsubscribeScroll();
      io.disconnect();
    };
  }, [heroSize, servicesSize, ourApproachSize, ourWayOfWorkingSize, scrollY, isMobile]);

  return (
    <div className="services-page">
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{seoData.title}</title>
          <meta name="description" content={seoData.metaDescription} />
        </Helmet>
        <Header dark={darkHeaderMenuIcon} />
        <Hero innerRef={heroRef} />
      </div>
      <div className="services-wrapper">
        {/*<div className="servicess-observed servicess-top"></div>*/}
        <div className="services-observed services-bottom"></div>
        <div className="our-services mesh-blue our-services-content">
          <OurServices innerRef={servicessRef} />
        </div>
      </div>
      <div className="our-approach">
        <div className="approach-observed"></div>
        <OurApproach innerRef={ourApproachRef} />
      </div>
      <Toolbox innerRef={ourWayOfWorkingRef} />
    </div>
  );
};

export default Home;
