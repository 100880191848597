import React from 'react';
import AliceCarousel from 'react-alice-carousel';

import squareCircle from 'src/assets/images/about-assets/cards/square-circle.svg';
import stars from 'src/assets/images/about-assets/cards/stars.svg';
import target from 'src/assets/images/about-assets/cards/target.svg';

const items = [
  <div key={1} className="item" data-value="1">
    <div className="basics-card">
      <h3 className="card-title">WHY</h3>
      <div className="paragraph-2 card-content">
        Empowering users and communities by prioritizing their voices and addressing their needs through intentional
        digital solutions.
      </div>
      <img className="card-icon" src={squareCircle} alt="icon" />
    </div>
  </div>,
  <div key={2} className="item" data-value="2">
    <div className="basics-card">
      <h3 className="card-title">WHAT</h3>
      <div className="paragraph-2 card-content">
        Navigating the journey toward digital mastery, offering tailored solutions that propel to triumph and positive
        impact.
      </div>
      <img className="card-icon" src={stars} alt="icon" />
    </div>
  </div>,
  <div key={3} className="item" data-value="3">
    <div className="basics-card">
      <h3 className="card-title">HOW</h3>
      <div className="paragraph-2 card-content">
        Working hard, thinking fast, and taking precise actions to make sure that we get things right, for the user and
        the client.
      </div>
      <img className="card-icon" src={target} alt="icon" />
    </div>
  </div>,
];

const BasicsMobileCarousel = () => {
  return <AliceCarousel mouseTracking items={items} disableButtonsControls={true} controlsStrategy="alternate" />;
};

export default BasicsMobileCarousel;
