import React, { useMemo, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';

import { OUR_TEAM, TeamMember } from 'src/assets/data/our-team';

const responsive = {
  480: { items: 1 },
  768: { items: 2 },
};

const OurTeamMobileCarousel = () => {
  const [hoveredItem, setHoveredItem] = useState<TeamMember | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const onSlideChanged = ({ item }) => setActiveIndex(item);

  const cards = useMemo(() => {
    return OUR_TEAM.map((item, index) => (
      <div
        key={item.id}
        className="our-team-mobile-carousel-card"
        onMouseEnter={() => {
          setHoveredItem(item);
        }}
        onMouseLeave={() => {
          setHoveredItem(null);
        }}
      >
        <div className="img-container">
          {hoveredItem?.id === item.id ? (
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                background: item.hoverBg,
              }}
            />
          ) : (
            <div className={'gray-bg-div'} />
          )}
          <img src={item.picture} alt={item.name} />
        </div>

        <div className="team-member-name">{hoveredItem?.id === item.id ? item.role : item.name}</div>
        <div className="paragraph-6">{item.description}</div>
      </div>
    ));
  }, [hoveredItem]);

  return (
    <AliceCarousel
      activeIndex={activeIndex}
      onSlideChanged={onSlideChanged}
      mouseTracking
      disableButtonsControls
      items={cards}
      responsive={responsive}
      controlsStrategy="responsive"
      paddingRight={140}
    />
  );
};

export default OurTeamMobileCarousel;
