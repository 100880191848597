import React, { MutableRefObject, useMemo } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';

import './Hero.scss';

const Hero = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  const isMobile = useIsMobile();

  const HeroComponent = useMemo(() => {
    return isMobile ? MobileComponent() : DesktopComponent();
  }, [isMobile]);

  function DesktopComponent() {
    return (
      <div className="mix-blend-screen" ref={innerRef}>
        <div className="hero-content">
          <div className="hero-content-text">
            <h1 className="capitalised centered">We design, build</h1>

            <div className="middle-row">
              <div className="small-text-wrapper">
                <p className="paragraph-3 align-right hero-subheading">
                  User driven, guided by data and
                  <br />
                  precisely engineered.
                </p>
              </div>

              <h1 className="capitalised">And Connect</h1>
            </div>

            <h1 className="capitalised">Digital solutions</h1>
          </div>
        </div>
      </div>
    );
  }

  function MobileComponent() {
    return (
      <div ref={innerRef} className="mix-blend-screen snap-point">
        <div className="hero-content">
          <h1 className="capitalised centered">We design, build and</h1>
          <h1 className="capitalised align-right">connect</h1>
          <h1 className="capitalised align-left">digital solutions</h1>

          <div className="middle-row">
            <div className="small-text-wrapper">
              <p className="paragraph-3 align-left">
                User driven, guided by data and <br />
                precisely engineered.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return HeroComponent;
};

export default Hero;
