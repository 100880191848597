import React, { useCallback, useState, useEffect } from 'react';
import classnames from 'classnames';
import emailjs from '@emailjs/browser';

import Button from 'src/components/shared/Button/Button';
import { useContactForm, FormState } from 'src/components/shared/ContactForm/useContactForm';
import ValidFieldCheckmarkIcon from 'src/assets/images/valid-field-checkmark.svg';
import config from 'src/config';

import './ContactForm.scss';

const ContactForm = () => {
  const { contactForm, onFormSubmit, isSubmittingForm } = useContactForm();

  const [showThankYouPopup, setShowThankYouPopup] = useState(false);
  const [formSize, setFormSize] = useState(0);

  const { register, getFieldState, handleSubmit, formState } = contactForm;
  const { errors } = formState;

  const onSubmit = async (data: FormState) => {
    if (isSubmittingForm) {
      return;
    }

    try {
      onFormSubmit(data);
      const { name, email, message } = data;
      const values = {
        name,
        email,
        message,
      };

      const result = await emailjs.send(
        config.EMAILJS_SERVICE_ID as string,
        config.EMAILJS_TEMPLATE_ID as string,
        values,
        config.EMAILJS_PUBLIC_KEY as string
      );

      if (result.status === 200) {
        setShowThankYouPopup(true);
      }
    } catch (err) {}
  };

  const fieldState = useCallback(
    (field: string) => {
      return {
        isDirty: getFieldState(field, formState).isDirty,
        isValid: !errors[field]?.message,
      };
    },
    [getFieldState, errors]
  );

  useEffect(() => {
    const formElement = document.getElementsByTagName('form')?.[0];

    if (formElement) {
      const formHeight = formElement.clientHeight;
      setFormSize(formHeight);
    }
  }, []);

  const renderThankYouPopup = () => {
    return (
      <div className="form-thank-you">
        <div className="paragraph-3 capitalised">
          <img className="valid-checkmark" src={ValidFieldCheckmarkIcon} alt="valid" />
          Message sent!
        </div>

        <div className="paragraph-3">
          Thank you for contacting us. We have received your message. We will get back to you as soon as possible.
        </div>
      </div>
    );
  };

  return (
    <div className="form-container" style={{ minHeight: formSize ?? 400 }}>
      {showThankYouPopup && renderThankYouPopup()}
      {!showThankYouPopup && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="label">
            YOUR NAME<span>*</span>
          </div>
          <div
            className={classnames('input-wrapper', {
              error: errors.name,
            })}
          >
            <input placeholder="Jane Doe" {...register('name')} disabled={isSubmittingForm} />
            {errors.name?.message && <p className="error-message">{errors.name?.message}</p>}
            {fieldState('name').isDirty && fieldState('name').isValid && (
              <img className="valid-checkmark" src={ValidFieldCheckmarkIcon} alt="valid" />
            )}
          </div>

          <div className="label">
            YOUR MAIL<span>*</span>
          </div>
          <div
            className={classnames('input-wrapper', {
              error: errors.email,
              valid: fieldState('email').isDirty && fieldState('email').isValid,
            })}
          >
            <input {...register('email')} placeholder="jane.doe@company.com" disabled={isSubmittingForm} />
            {errors.email?.message && <p className="error-message">{errors.email?.message}</p>}
            {fieldState('email').isDirty && fieldState('email').isValid && (
              <img className="valid-checkmark" src={ValidFieldCheckmarkIcon} alt="valid" />
            )}
          </div>

          <div className="label">
            YOUR MESSAGE<span>*</span>
          </div>
          <div
            className={classnames('input-wrapper', {
              error: errors.message,
              valid: fieldState('message').isDirty && fieldState('message').isValid,
            })}
          >
            <textarea
              {...register('message')}
              placeholder="What would you like to share with us?"
              rows={6}
              disabled={isSubmittingForm}
            />
            {errors.message?.message && <p className="error-message">{errors.message?.message}</p>}
            {fieldState('message').isDirty && fieldState('message').isValid && (
              <img className="valid-checkmark" src={ValidFieldCheckmarkIcon} alt="valid" />
            )}
          </div>

          <div className={classnames('checkbox-wrapper', { error: errors.livoritPrivacyPolicyConsent })}>
            <label className="checkbox-text">
              <input
                type="checkbox"
                className="checkbox-custom"
                {...register('livoritPrivacyPolicyConsent')}
                disabled={isSubmittingForm}
              />
              I have read and agree to Livorits{' '}
              <a href="/privacy" target={'blank'}>
                privacy policy
              </a>
              .
            </label>
            {errors.livoritPrivacyPolicyConsent?.message && (
              <p className="error-message">{errors.livoritPrivacyPolicyConsent?.message}</p>
            )}
          </div>

          <Button
            disabled={isSubmittingForm}
            isLoading={isSubmittingForm}
            text={`Submit`}
            withArrow={true}
            margin={'38px 0 0 0'}
            minWidth={'285px'}
            textTransform={'uppercase'}
            theme={'transparent'}
            type={'submit'}
          />
        </form>
      )}
    </div>
  );
};

export default ContactForm;
