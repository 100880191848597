import React from 'react';
import HeaderMenuAnimated from 'src/components/shared/HeaderMenuAnimated/HeaderMenuAnimated';

import './Header.scss';

interface HeaderProps {
  dark?: boolean;
  fixed?: boolean;
}

const Header = ({ dark = true, fixed = false }: HeaderProps) => {
  return (
    <div className={`header-container${fixed ? ' fixed' : ''}`}>
      <HeaderMenuAnimated dark={dark} />
    </div>
  );
};

export default Header;
