import React, { ButtonHTMLAttributes, useMemo } from 'react';

import { ReactComponent as ArrowRightLight } from 'src/assets/images/arrow-right-light.svg';
import { ReactComponent as ArrowRightDark } from 'src/assets/images/arow-right-dark.svg';
import Loader from 'src/components/shared/Loader/Loader';

import './Button.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  margin?: string;
  minWidth?: string;
  width?: string;
  disabled?: boolean;
  isLoading?: boolean;
  overrideClassnames?: string;
  style?: Record<any, any>;
  textTransform?:
    | 'unset'
    | 'none'
    | 'inherit'
    | '-moz-initial'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'capitalize'
    | 'full-size-kana'
    | 'full-width'
    | 'lowercase'
    | 'uppercase'
    | undefined;
  withArrow?: boolean;
  theme?: 'light' | 'dark' | 'transparent';
}

const Button = ({
  text,
  withArrow,
  margin,
  minWidth,
  width,
  disabled,
  isLoading,
  textTransform,
  overrideClassnames,
  style,
  theme,
  ...props
}: ButtonProps) => {
  const styles = {
    ...style,
    margin: margin ?? '0px',
    minWidth: minWidth ?? 'unset',
    width: width ?? 'fit-content',
    textTransform: textTransform ?? 'none',
  };
  const classNames = theme === 'light' ? 'light' : theme === 'dark' ? 'dark' : 'transparent';

  const IconComponent = theme === 'light' ? ArrowRightDark : ArrowRightLight;

  const ButtonContent = useMemo(() => {
    if (isLoading) {
      return <Loader styles={{ margin: '0 auto' }} theme={theme as string} />;
    }

    return (
      <>
        {text}

        {withArrow && (
          <div className="button-arrow-container">
            <IconComponent className="button-arrow-icon" />
          </div>
        )}
      </>
    );
  }, [isLoading, text, withArrow, theme]);

  return (
    <button
      style={styles}
      disabled={disabled}
      {...props}
      className={`${classNames}${overrideClassnames ? ` ${overrideClassnames}` : ''}`}
    >
      {ButtonContent}
    </button>
  );
};

export default Button;
