import React, { useMemo, useState } from 'react';
import { useIsMobile } from 'src/hooks/useIsMobile';

import BlueButton from 'src/components/shared/Button/Button';

import OurTeamMobileCarousel from 'src/components/landing-page/ProjectsAndTeam/OurTeamMobileCarousel';
import { OUR_TEAM, STATISTICS, TeamMember } from 'src/assets/data/our-team';
import { Button } from 'antd';

const OurTeam = () => {
  const isMobile = useIsMobile();
  const [hoveredItem, setHoveredItem] = useState<TeamMember | null>(null);

  const StatisticsComponent = useMemo(() => {
    if (isMobile) {
      return (
        <div className="our-team-note-statistics-mobile">
          {STATISTICS.map((item, index) => (
            <div key={index} className="statistics-item">
              <h2>{item.amount}</h2>
              <div className="paragraph-4">{item.text}</div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="our-team-note-statistics-desktop">
        {STATISTICS.map((item, index) => (
          <div key={index} className="statistics-item">
            <h2>{item.amount}</h2>
            <div className="paragraph-3">{item.text}</div>
          </div>
        ))}
      </div>
    );
  }, [isMobile]);

  const CardsComponent = useMemo(() => {
    if (isMobile) {
      return <OurTeamMobileCarousel />;
    }

    return (
      <div className="our-team-cards">
        {OUR_TEAM.map((item, index) => (
          <div
            key={index}
            className="our-team-cards-item"
            onMouseEnter={() => {
              setHoveredItem(item);
            }}
            onMouseLeave={() => {
              setHoveredItem(null);
            }}
          >
            <div className="img-container">
              {hoveredItem?.id === item.id ? (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    background: item.hoverBg,
                  }}
                />
              ) : (
                <div className={'gray-bg-div'} />
              )}
              <img src={item.picture} alt={item.name} />
            </div>

            <div className="team-member-name">{hoveredItem?.id === item.id ? item.role : item.name}</div>
            <div className="paragraph-6">{item.description}</div>
          </div>
        ))}
      </div>
    );
  }, [isMobile, hoveredItem]);

  return (
    <div className="our-team-wrapper">
      <span className="separator-150 observed" />
      <div className="our-team">
        {isMobile ? (
          <h2 className="our-team-title">MEET (Y)OUR TEAM</h2>
        ) : (
          <h2 className="our-team-title">MEET (Y)OUR TEAM</h2>
        )}
        <div className="our-team-text paragraph-2">
          Our studio is home to a colorful team - unique in their character, skills and individual as our fingerprints.
          We work and communicate with passion, have untamed creativity, in-depth technical expertise and a passion for
          solving complex problems.
        </div>

        {CardsComponent}

        <a href="/about" className="about-button">
          <BlueButton
            text={`About our team`}
            withArrow={true}
            minWidth={'285px'}
            textTransform={'uppercase'}
            theme={'light'}
            overrideClassnames={'header-button'}
          />{' '}
        </a>

        <div className="paragraph-2 our-team-note">
          In addition to our core team, we include a carefully chosen network of european-based digital engineers,
          creatives and managers with deep expertise in technology, agile frameworks and project delivery.
        </div>

        {StatisticsComponent}
      </div>
    </div>
  );
};

export default OurTeam;
