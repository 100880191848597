import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import classnames from 'classnames';

import { useIsMobile } from 'src/hooks/useIsMobile';
import { WHAT_YOU_CAN_EXPECT_FROM_US } from 'src/assets/data/what-you-can-expect-from-us';
import ChevronDownIcon from 'src/assets/images/chevron-down-white.svg';

import BlueButton from 'src/components/shared/Button/Button';

import './WhatYouCanExpectFromUs.scss';

const WhatYouCanExpectFromUs = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  const isMobile = useIsMobile();
  const [expandedIndex, setExpandedIndex] = useState<number | undefined>(undefined);

  const expandCard = useCallback(
    (index: number) => {
      if (expandedIndex === index) {
        setExpandedIndex(undefined);
      } else {
        setExpandedIndex(index);
      }
    },
    [expandedIndex]
  );

  const MobileComponent = () => (
    <div className="what-you-can-expect-from-us-cards-mobile">
      {WHAT_YOU_CAN_EXPECT_FROM_US.map((item, index) => (
        <div key={index} className="card-item">
          <div className="card-item-content">
            <img src={item.icon} alt="icon" />

            <div className="paragraph-3" dangerouslySetInnerHTML={{ __html: item.text }} />

            <img
              className={classnames('chevron-icon', {
                expanded: expandedIndex === index,
              })}
              src={ChevronDownIcon}
              alt="chevron"
              onClick={() => {
                expandCard(index);
              }}
            />
          </div>

          <div
            className={classnames('card-item-content-expanded', {
              show: expandedIndex === index,
            })}
          >
            <ul>
              {item.bullets.map((bullets, index) => (
                <li key={index} className="paragraph-4">
                  {bullets}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );

  const DesktopComponent = () => (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
      className="what-you-can-expect-from-us-cards-desktop"
    >
      {WHAT_YOU_CAN_EXPECT_FROM_US.map((item, index) => (
        <div key={item.id} className="card-item">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={item.icon} alt="icon" />

              <div className="paragraph-2" dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>

            <div className="flip-card-back">
              <ul>
                {item.bullets.map((bullets, index) => (
                  <li key={index} className="paragraph-4">
                    {bullets}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </motion.div>
  );

  const CardsComponent = useMemo(() => {
    return isMobile ? MobileComponent() : DesktopComponent();
  }, [isMobile, expandedIndex]);

  return (
    <div className="clippy-zone transparent-blue snap-create-point" ref={innerRef}>
      <div className="content-wrapper larger-height">
        <span className="observed in-clippy" />
        <div className="fixed-clippy-element">
          <div className={`${isMobile ? ' no-padding' : ''}`}>
            <div className="what-you-can-expect-from-us-content">
              <div className="what-you-can-expect-from-us-content-text">
                <h2 className="capitalised">What you can</h2>
                <h2 className="capitalised">Expect from us</h2>
                <div className="divider mobile" />
                <div className="user-experience-text paragraph-3">
                  Navigate the digital universe with Livorit. Whether you&apos;re sparking an idea, shaping a strategy,
                  or scaling your operations, we&apos;ve got every aspect of your digital journey covered. Experience
                  end-to-end excellence, all in one place.
                </div>
              </div>
              {CardsComponent}
            </div>
          </div>

          <a href="/services" className="about-button to-services">
            <BlueButton
              text={`Our Services`}
              withArrow={true}
              minWidth={'285px'}
              textTransform={'uppercase'}
              theme={'transparent'}
            />{' '}
          </a>
        </div>
      </div>
    </div>
  );
};

export default WhatYouCanExpectFromUs;
