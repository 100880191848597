import React, { MutableRefObject } from 'react';
import { motion } from 'framer-motion';

import Footer, { FooterType } from 'src/components/shared/Footer/Footer';

import './UserExperience.scss';

const UserExperience = ({ innerRef }: { innerRef: MutableRefObject<any> }) => {
  return (
    <motion.div className="framer snap-point">
      <div className="fixed-container clippy-zone" ref={innerRef}>
        <div className="fixed-element">
          <div className="flexbox user-experience-text-wrapper">
            <h2 className="capitalised">From first idea to</h2>
            <h2 className="capitalised">lasting impression</h2>
            <div className="divider mobile" />
            <div className="user-experience-text paragraph-3">
              Livorit is your one-stop digital destination. Present us with your idea, and we&apos;ll transform it into
              reality. Our end-to-end expertise ensures success at every phase of your journey.
            </div>
          </div>
          <Footer type={FooterType.WITH_LETS_TALK_BUTTON} rootClassName="footer-container" />
        </div>
      </div>
    </motion.div>
  );
};

export default UserExperience;
