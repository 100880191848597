import React, { useCallback, useState } from 'react';
import classnames from 'classnames';

import { Item } from 'src/assets/data/why-our-team';
import ChevronDownIcon from 'src/assets/images/chevron-down-blue.svg';

interface ExpandableCardsProps {
  data: Item[];
}

const ExpandableCards = ({ data }: ExpandableCardsProps) => {
  const [expandedIndex, setExpandedIndex] = useState<number | undefined>(undefined);

  const expandCard = useCallback(
    (index: number) => {
      if (expandedIndex === index) {
        setExpandedIndex(undefined);
      } else {
        setExpandedIndex(index);
      }
    },
    [expandedIndex]
  );

  return (
    <div className="expandable-cards-mobile">
      {data.map((item, index) => (
        <div key={index} className="card-item">
          <div className="card-item-content">
            <img src={item.icon} alt="icon" />

            <div className="paragraph-3" dangerouslySetInnerHTML={{ __html: item.title }} />

            <img
              className={classnames('chevron-icon', {
                expanded: expandedIndex === index,
              })}
              src={ChevronDownIcon}
              alt="chevron"
              onClick={() => {
                expandCard(index);
              }}
            />
          </div>

          <div
            className={classnames('card-item-content-expanded', {
              show: expandedIndex === index,
            })}
          >
            <div className="paragraph-4">{item.text}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExpandableCards;
