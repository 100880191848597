import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useScroll } from 'framer-motion';
import { useIsMobile } from 'src/hooks/useIsMobile';

import { useSize } from 'src/hooks/useSize';
import { getSeoData } from 'src/SEO/seo.helpers';

import Header from 'src/components/shared/Header/Header';
import Footer, { FooterType } from 'src/components/shared/Footer/Footer';

import Hero from 'src/components/landing-page/Hero/Hero';
import UserExperience from 'src/components/landing-page/UserExperience/UserExperience';
import WhatYouCanExpectFromUs from 'src/components/landing-page/WhatYouCanExpectFromUs/WhatYouCanExpectFromUs';
import ProjectsAndTeam from 'src/components/landing-page/ProjectsAndTeam/ProjectsAndTeam';
import WhatMakesUsSpecial from 'src/components/landing-page/WhatMakesUsSpecial/WhatMakesUsSpecial';
import VideoBackground from 'src/components/shared/VideoBackground/VideoBackground';
import HeroVideo from 'src/assets/videos/livorit-animation.mp4';
import GradientEdge from 'src/assets/images/gradient-edge.svg';

const Landing = () => {
  const { scrollY } = useScroll();
  const location = useLocation();
  const isMobile = useIsMobile();

  const heroRef = useRef(null);
  const userExperienceRef = useRef(null);
  const whatYouCanExpectFromUsRef = useRef(null);
  const projectsAndTeamRef = useRef(null);
  const whatMakesUsSpecialRef = useRef(null);

  const heroSize = useSize(heroRef);
  const userExperienceSize = useSize(userExperienceRef);
  const whatYouCanExpectFromUsSize = useSize(whatYouCanExpectFromUsRef);
  const projectsAndTeamSize = useSize(projectsAndTeamRef);
  const whatMakesUsSpecialSize = useSize(whatMakesUsSpecialRef);

  const [darkHeaderMenuIcon, setDarkHeaderMenuIcon] = useState(true);
  const [footerType, setFooterType] = useState<FooterType>(FooterType.NO_FOOTER);

  const seoData = useMemo(() => {
    return getSeoData(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const io = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.classList.toggle('in-view', entry.isIntersecting);
        });
      },
      { rootMargin: '0px' }
    );

    // Declares what to observe, and observes its properties.
    const boxElList = document.querySelectorAll('.observed');
    boxElList.forEach((el) => {
      io.observe(el);
    });

    function updateOnScroll() {
      const scroll = scrollY.get();

      const heroOverlay = heroSize?.height - 60;
      const whatYouCanExpectFromUsOverlay =
        heroSize.height + userExperienceSize.height + whatYouCanExpectFromUsSize.height - (isMobile ? 25 : 80);
      const projectsAndTeamOverlay =
        heroSize.height +
        userExperienceSize.height +
        whatYouCanExpectFromUsSize.height +
        projectsAndTeamSize.height -
        (isMobile ? -20 : 80);
      const whatMakesUsSpecialOverlay =
        heroSize.height +
        userExperienceSize.height +
        whatYouCanExpectFromUsSize.height +
        projectsAndTeamSize.height +
        whatMakesUsSpecialSize.height;

      if (scroll >= heroOverlay && scroll < whatYouCanExpectFromUsOverlay) {
        setDarkHeaderMenuIcon(false);
      } else if (scroll >= whatYouCanExpectFromUsOverlay && scroll < projectsAndTeamOverlay) {
        setDarkHeaderMenuIcon(true);
      } else if (scroll >= projectsAndTeamOverlay && scroll < whatMakesUsSpecialOverlay) {
        setDarkHeaderMenuIcon(false);
      } else {
        setDarkHeaderMenuIcon(true);
      }

      if (scroll >= whatMakesUsSpecialOverlay - projectsAndTeamSize.height) {
        setFooterType(FooterType.WITH_LINKS);
      }
    }

    const unsubscribeScroll = scrollY.onChange(updateOnScroll);
    return () => {
      unsubscribeScroll();
      io.disconnect();
    };
  }, [heroSize, userExperienceSize, whatYouCanExpectFromUsSize, projectsAndTeamSize, scrollY, isMobile]);

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.metaDescription} />
      </Helmet>

      <Header dark={darkHeaderMenuIcon} fixed={true} />

      <div className="refferenced-div" id="top">
        <div className="observed mix-blend-screen">
          <Hero innerRef={heroRef} />
        </div>
        <UserExperience innerRef={userExperienceRef} />
        <VideoBackground video={HeroVideo} />
      </div>

      <div className="what-you-can-expect-from-us">
        <img className={'gradient-edge'} src={GradientEdge} alt="gradient-edge" />
        <WhatYouCanExpectFromUs innerRef={whatYouCanExpectFromUsRef} />
      </div>

      <div className="livorit-solutions ">
        <ProjectsAndTeam innerRef={projectsAndTeamRef} />
      </div>

      <WhatMakesUsSpecial innerRef={whatMakesUsSpecialRef} />

      <Footer type={footerType} rootClassName="footer-container homepage-footer" />
    </div>
  );
};

export default Landing;
