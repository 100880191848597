import Card1 from 'src/assets/images/why-our-solutions/card1.svg';
import Card2 from 'src/assets/images/why-our-solutions/card2.svg';
import Card3 from 'src/assets/images/why-our-solutions/card3.svg';

export interface Item {
  id: number;
  icon: any;
  title: string;
  text: string;
}

export const WHY_OUR_SOLUTIONS: Item[] = [
  {
    id: 1,
    icon: Card1,
    title: '<span>Decisive<br/>Edge</span>',
    text: 'Our solutions consider multiple perspectives of your business to catch and redirect existing momentum and create new one.'
  },
  {
    id: 2,
    icon: Card2,
    title: '<span>End-to-End<br/>Solutions</span>',
    text: 'We work with you to create a comprehensive and cohesive digital presence that helps you achieve your business goals.',
  },
  {
    id: 3,
    icon: Card3,
    title: '<span>Customized<br/>and Scalable</span>',
    text: 'We understand and anticipate your needs, delivering personalized solutions that drive lasting success.'
  },
];
