import { useEffect, useState } from 'react';
import useFontFaceObserver from 'use-font-face-observer';

export const useIsFontLoaded = () => {
  const [loaded, setLoaded] = useState(false);

  const isFontLoaded = useFontFaceObserver([
    { family: 'MonetaSans-Black' },
    { family: 'MonetaSans-Regular' },
    { family: 'MonetaSans-Light' },
    { family: 'MonetaSans-Bold' },
  ]);

  useEffect(() => {
    setLoaded(isFontLoaded);
  }, [isFontLoaded]);

  return { loaded };
};
