import { RoutesEnum } from 'src/rotues';
import { getEnumKeyByEnumValue } from 'src/utilities/getEnumKeyByEnumValue';

export const seoData = {
  root: {
    title: 'Livorit - Berlin-Based Digital Solutions Studio',
    metaDescription:
      "Livorit, a Berlin-based digital solutions studio, designs and builds user-driven digital solutions with data-guided precision. Let's talk about transforming your ambitious ideas into lasting impressions.",
  },
  landing: {
    title: 'Livorit - Berlin-Based Digital Solutions Studio',
    metaDescription:
      "Livorit, a Berlin-based digital solutions studio, designs and builds user-driven digital solutions with data-guided precision. Let's talk about transforming your ambitious ideas into lasting impressions.",
  },
  legal: {
    title: 'Livorit Imprint - Legal Information & Company Details',
    metaDescription:
      "Find Livorit's legal information, company details, and contact information on our Imprint page, as required by German law.",
  },
  privacy: {
    title: 'Livorit Privacy Policy - Protecting Your Data',
    metaDescription:
      "Livorit's Privacy Policy: Understand how we protect your personal information, prioritize your privacy, and maintain transparency in our data handling practices.",
  },
  about: {
    title: 'About Livorit - Vision, Team, and Commitment',
    metaDescription:
      "Learn about Livorit's forward-thinking team, dedicated to creating impactful digital solutions. Our vision, team, and commitment shape the digital space for users and communities.",
  },
  services: {
    title: 'Livorit Services - Comprehensive Digital Solutions',
    metaDescription:
      'Livorit offers comprehensive digital services from strategy to development, branding, marketing, and AI. Discover our tailored, cutting-edge solutions and drive your digital excellence today.',
  },
};

interface SeoData {
  title?: string;
  metaDescription?: string;
}

export const setSEO = (data: SeoData) => {
  data.title = data?.title || seoData.root.title;
  data.metaDescription = data?.metaDescription || seoData.root.metaDescription;

  document.title = data.title;
  document.querySelector('meta[name="description"]')?.setAttribute('content', data.metaDescription);
};

export const getSeoData = (route: string) => {
  if (!route) {
    return seoData.root;
  }

  const routeKey = getEnumKeyByEnumValue(RoutesEnum, route);

  if (!routeKey) {
    return seoData.root;
  }

  return seoData[`${routeKey.toLowerCase()}`];
};
