import React from 'react';
import classnames from 'classnames';

import './Loader.scss';

const Loader = ({ styles, theme = 'dark' }: { styles?: Record<any, any>; theme?: string }) => {
  return (
    <span
      className={classnames(
        'loader',
        { light: theme === 'light' },
        { dark: theme === 'dark' },
        { transparent: theme === 'transparent' }
      )}
      style={styles ?? {}}
    />
  );
};

export default Loader;
