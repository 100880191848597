import placeholderIcon from 'src/assets/images/avatar.svg';
import placeholder from 'src/assets/companies/dark-Cariad.svg';

/* Company images */

import cariad from 'src/assets/images/slider-logos/cariad_web 1.png';
import diconium from 'src/assets/images/slider-logos/dark-Diconium.png';
import senacor from 'src/assets/images/slider-logos/dark-Senacor.png';
import dadb from 'src/assets/images/slider-logos/dark-DADB.png';
import golem from 'src/assets/images/slider-logos/dark-Golem.png';
import abilogo from 'src/assets/images/slider-logos/abilogo.png';

export const slideData = [
  {
    id: 1,
    company: {
      image: cariad,
      companyname: 'Product Manager - CARIAD SE',
    },
    title: 'Establishment of a Customer Success Infrastructure',
    text: 'I very much appreciate the always professional cooperation with the colleagues from Livorit GmbH. Especially as an innovative partner, idea driver and communicator for creative solutions in the business field of customer success and web technologies.  ',
    image: placeholderIcon,
    name: 'Normann Nagel',
    tags: [
      'Agile Project Management',
      'Certified SAFe Program Consultant',
      'Content Strategy',
      'Customer Experience',
      'Customer Journey',
      'Digital Strategy',
      'Information Design ',
      'IT Strategy',
      'Product Owner',
      'Project Coordination',
      'Project Lead',
      'Project Management',
      'Project Planning',
      'SAFe',
      'Stakeholder Management',
      'Strategy Consulting',
      'UX Concept',
      'UX Design',
    ],
    companyLink: 'https://cariad.technology',
    location: 'Berlin',
    activeDate: '01/2021 - today',
    industry: 'Automotive Software Company',
    qualityScore: 4,
    scheduleScore: 5,
    communicationScore: 3,
    summary:
      'Our mission was to build a Customer Success Infrastructure for the PMT (Processes, Methods, Tools) technology area, enabling developers to work in their technical environment and providing stakeholders with all relevant information. The development focused on creating a comprehensive user portal.',
    servicesRendered: [
      'Consulting on the strategy and implementation of the Customer Success Vision, as well as integrating existing solutions.',
      'Stakeholder management and communication within a SAFe environment, involving approximately 800 employees in 8 Agile Release Trains and 93 Teams.',
      'Product owning and project management in a team of up to 14 members.',
      'Identity and User Experience Design of an Atlassian-based portal under our own brand.',
      'Informations design at the architecture, system, and software level.',
    ],
    results:
      'The project is currently still in the execution phase, but the services provided have set the company on a path to strengthen its digital presence and enhance customer satisfaction.',
  },
  {
    id: 2,
    company: {
      image: abilogo,
      companyname: 'CEO - Abiagenten AG',
    },
    title:
      'Digital Transformation and Market Strategy: Re-Invention of Product Portfolio and Introduction of an Abitur Event App ',
    text: "Livorit's transformation through digitalization is outstanding! Their portfolio revamp, strategic consulting, and innovative app are setting new benchmarks. Proud to partner with them!",
    image: placeholderIcon,
    name: 'Bernd Altwasser',
    tags: [
      'AWS',
      'Corporate Identity',
      'Data Migration',
      'Market Analysis',
      'Product Design',
      'Project Management',
      'Strategy Consulting',
      'Product Owning',
      'App Development',
    ],
    companyLink: 'https://abiagenten.de',
    location: 'Köln',
    activeDate: '11/2022 - today',
    industry: 'Event Management',
    qualityScore: 3,
    scheduleScore: 4,
    communicationScore: 5,
    summary:
      'Our client is an established company in the event market for high school graduates (Abiturienten) that required a comprehensive digitalization strategy to revamp its product portfolio and strengthen its market position. We support them in the development and implementation of this strategy through strategic consulting, database migration, corporate identity modernization, marketing and sales campaigns, as well as the conceptualization of an innovative mobile app.',
    servicesRendered: [
      'Strategic consulting for digitalization processes to prepare the company for digital transformation and process automation ',
      'Database consolidation and migration to AWS to optimize inefficient processes and increase the scalability of the platform.',
      'Target audience research and adaptation of corporate identity to modernize the brand and align the company better with the needs of its customers.',
      "Conceptualization of a mobile app to expand the company's offerings and provide customers with an innovative and user-friendly experience. This includes the implementation of back-office processes to ensure seamless integration with the existing infrastructure.",
    ],
    results:
      'The project is currently still in the execution phase, but the services provided have set the company on a path to strengthen its digital presence and enhance customer satisfaction.',
  },
  {
    id: 3,
    company: {
      image: diconium,
      companyname: 'Product Owner - Diconium Digital Solutions GmbH ',
    },
    title: 'Information and Documentation Management System ',
    text: 'I can highly recommend the colleagues from Livorit. They work professionally, are highly innovative, flexible, and extremely focused on customer needs. The communication and performance are simply outstanding, even with the numerous challenges faced in this project. I had no complaints whatsoever and had a truly excellent experience.',
    image: placeholderIcon,
    name: 'Arina Ignatenkova',
    tags: [
      'Automation Engineering',
      'Confluence',
      'Content Management',
      'Data Migration',
      'Database Administration',
      'Database Design',
      'Database Modeling',
      'Database Systems',
      'Frontend',
      'Information Architecture',
      'Information Management',
      'Interface Management',
      'Process Automation',
      'Product Documentation',
      'Project Documentation',
      'Python',
      'ScriptRunner for Jira',
      'System Documentation',
      'Technical Documentation',
      'User Documentation',
    ],
    companyLink: 'https://diconium.com',
    location: 'Berlin',
    activeDate: '07/2022 - 03/2023',
    industry: 'IT Consulting',
    qualityScore: 3,
    scheduleScore: 4,
    communicationScore: 5,
    summary:
      'Our task was to develop an Information and Documentation Management System along with a member database and integrate them with a frontend through automation engineering. The development involved creating a database system for collecting, structuring, and distributing information, as well as automating the generation of Confluence pages. ',
    servicesRendered: [
      'Information architecture in a complex environment with diverse data sources and complex requirements',
      'Development of a database concept for managing thousands of pieces of information across different system levels',
      'Development of an Information and Documentation Management System with associated processes and workflows',
      'Data migration from existing sources into the new system',
      'Automation of workflows based on Python script runners',
    ],
    results: '',
  },
  {
    id: 4,
    company: {
      image: dadb,
      companyname: 'CEO & Co-Founder - DADB Deutsche Akademie für Digitale Bildung',
    },
    title: 'Agiles Projektmanagement & Operations',
    text: "In close collaboration with Livorit, we've revolutionized productivity using agile project management and Asana. Their contributions have been pivotal in optimizing workflow efficiency.",
    image: placeholderIcon,
    name: 'Carsten Schroeder',
    tags: [
      'Agile Coaching',
      'Agile Methods',
      'Project Management',
      'Dashboard',
      'Key Performance Indicator',
      'Process Consulting',
      'Process Digitalization',
      'Process Automation',
      'Process Description',
    ],
    companyLink: 'https://www.dadb.com',
    location: 'Berlin',
    activeDate: '12/2021 - 03/2022',
    industry: 'Service Industry',
    qualityScore: 3,
    scheduleScore: 4,
    communicationScore: 5,
    summary:
      'In close collaboration with our client, we implemented agile project management and associated processes using the Asana environment to enhance productivity and optimize workflow efficiency.',
    servicesRendered: [
      'Designing and adapting processes to align with agile principles',
      'Establishing project structures and integrating process automation',
      'Providing agile coaching and facilitating the adoption of agile methods and tools',
      'Defining Key Performance Indicators (KPIs) and implementing a dashboard for real-time reporting',
    ],
    results: '',
  },
  {
    id: 5,
    company: {
      image: golem,
      companyname: 'CEO - Golem Media GmbH',
    },
    title: 'Development and Implementation of an Online Platform for IT Career Coaching and Coaching Products  ',
    text: 'Very professional collaboration! Always committed, proactive, reliable, excellent communication skills, and expertise. They integrated well into our processes and culture. We were very satisfied! ',
    image: placeholderIcon,
    name: 'Benjamin Sterbenz ',
    tags: [
      'Business Modelling',
      'Customer Relationship Management',
      'Dashboard',
      'Digital Strategy',
      'E-Payment',
      'Platform Development',
      'Product Owner',
      'ReactJS',
      'Shopify',
      'Strategy Consulting',
      'UX Strategy',
      'Marketing',
      'Website Tracking',
    ],
    companyLink: 'https://karrierewelt.golem.de',
    location: 'Berlin',
    activeDate: '10/2020 - 10/2021',
    industry: 'Media, Internet and IT',
    qualityScore: 3,
    scheduleScore: 4,
    communicationScore: 5,
    summary:
      'Our client sought assistance in advancing their career business segment. We conceptualized, developed, and introduced an online platform for IT career coaching and other coaching products. Our services included strategic consulting, product ownership, design, integration with booking and payment systems, coach acquisition, and platform marketing. ',
    servicesRendered: [
      'Strategic consulting and workshops for business field development and monetization, assisting the client in identifying growth opportunities and designing a profitable business model',
      'Conceptualization and design of the online platform for IT career coaching and other coaching products, providing the client with a scalable solution',
      "Product ownership during development to ensure the product met the client's requirements",
      'Integration of booking and payment systems for a user-friendly customer experience',
      "Acquisition of qualified coaches to ensure the client's coaching offerings were led by professionals",
      "Marketing of the platform to increase awareness and expand the client's reach",
      'Definition and collection of KPIs, analytics dashboard, and reporting setup for management, supporting the client in monitoring platform performance and decision-making',
    ],
    results:
      'Through our dedication to development and collaborative work on the online platform, our client successfully expanded their career business segment and established a platform for IT professionals. The platform achieved high customer satisfaction levels and contributed to the scaling and expansion of the business model. ',
  },
  {
    id: 6,
    company: {
      image: senacor,
      companyname: 'Business Development - Senacor AG',
    },
    title: 'Digitalization and Data Integration for Sales Processes and Reportings with Pipedrive',
    text: 'Very pleasnt collaboration. We got professional and reliable support. Very responsive, Livorit had solutions for all of our questions. We will definetely ask them for further collaborations',
    image: placeholderIcon,
    name: 'Kathrin Behre',
    tags: [
      'API Engineering',
      'Customer Relationship Management',
      'Dashboard',
      'Data Pipeline',
      'Project Management',
      'Reporting Services',
      'Strategy Consulting',
    ],
    companyLink: 'https://senacor.com',
    location: 'Köln',
    activeDate: '01/2021 - 06/2021',
    industry: 'IT Consulting in Financial Services',
    qualityScore: 3,
    scheduleScore: 4,
    communicationScore: 5,
    summary:
      'We supported our client in optimizing and automating their sales processes and reportings by migrating Excel contacts and evaluations into Pipedrive, automating sales workflows, and developing customized dashboards and reportings. The successful collaboration led to increased sales efficiency and improved decision-making for the management. ',
    servicesRendered: [
      'Digitalization of sales processes and reportings to support the client in optimizing their sales activities',
      'Migration of Excel files and evaluations into Pipedrive to ensure a seamless transition and efficient data management',
      "Automation of sales processes to minimize manual work steps and enhance the sales team's efficiency",
      'Definition and integration of key performance indicators (KPIs) to measure sales process success and enable informed decision-making',
      "Alignment with the sales team to ensure processes and dashboards meet the company's and sales team's needs and requirements",
      'Development of customized dashboards and reportings for quick and accurate monitoring of sales activities',
      'Integration of data and reporting for management to make informed decisions based on sales data',
      'Monitoring and optimization of sales KPIs to ensure continuous improvement of the sales process',
    ],
    results: '',
  },
];
