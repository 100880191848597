import React from 'react';

import Header from 'src/components/shared/Header/Header';
import Footer from 'src/components/shared/Footer/Footer';
import UnderConstruction from 'src/components/home/UnderConstruction/UnderConstruction';

const Home = () => {
  return (
    <div>
      <Header />

      <UnderConstruction />

      <Footer />
    </div>
  );
};

export default Home;
